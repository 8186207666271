import React, { useState } from "react";
import "./Header.css";

import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import QuotePopup from "./QuotePopup";

export default function Header() {
  const path = window.location.pathname;
  const width = window.innerWidth;

  const [modal, setModal] = useState(false);

  return (
    <div className="header">
      <div className="container">
        <Navbar expand="lg">
          <Container fluid>
            <Nav.Link href="/" className="brand-logo py-2 navbar-brand">
              <img
                src={require("../Images/mainLogo.png")}
                alt="Logo"
                height="auto"
                width="100%"
              />
            </Nav.Link>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-lg`}
              id="CloseFunction"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
            >
              <Offcanvas.Header
                className="justify-content-start align-items-center pe-4 pt-4 "
                closeButton
              ></Offcanvas.Header>
              <Offcanvas.Body className="xyz">
                <Nav className="justify-content-end flex-grow-1 align-items-md-center  ">
                  <Nav.Link
                    id="Home"
                    className={`${
                      path === "/" ? "active" : ""
                    } nav-link secondary-txt px-md-4 p-0`}
                    href="/"
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    id="Architecture"
                    className={`${
                      path === "/archiresidential" && "/archicommercial"
                        ? "active"
                        : ""
                    } nav-link secondary-txt px-md-4 pe-md-2 pb-0 pb-md-2`}
                    href="/archiresidential"
                  >
                    <NavDropdown
                      title="Architecture"
                      id="collapsible-nav-dropdown"
                    >
                      <NavDropdown.Item href="archiresidential">
                        Residential
                      </NavDropdown.Item>
                      <NavDropdown.Divider className="d-md-block d-none" />
                      <NavDropdown.Item href="/archicommercial">
                        Commercial
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav.Link>
                  <Nav.Link
                    id="Interiors"
                    className={`${
                      path === "/interiorresidential" && "/interiorcommercial"
                        ? "active"
                        : ""
                    } nav-link secondary-txt px-md-4 pe-md-2  p-0`}
                    href="/interiorresidential"
                  >
                    <NavDropdown
                      title="Interiors"
                      id="collapsible-nav-dropdown"
                    >
                      <NavDropdown.Item href="/interiorresidential">
                        Residential
                      </NavDropdown.Item>
                      <NavDropdown.Divider className="d-md-block d-none" />
                      <NavDropdown.Item href="/interiorcommercial">
                        Commercial
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav.Link>
                  <Nav.Link
                    id="Turnkey"
                    className={`${
                      path === "/turnkey" ? "active" : ""
                    } nav-link secondary-txt px-md-4  `}
                    href="/turnkey"
                  >
                    Turnkey
                  </Nav.Link>
                  <Nav.Link
                    id="Aboutus"
                    className={`${
                      path === "/aboutus" ? "active" : ""
                    } nav-link secondary-txt px-md-4  `}
                    href="/aboutus"
                    eventKey={2}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    id="Contact"
                    className={`${
                      path === "/contactus" ? "active" : ""
                    } nav-link secondary-txt px-md-4 `}
                    href="/contactus"
                    eventKey={3}
                  >
                    Contact Us
                  </Nav.Link>

                  {width > 480 ? (
                    path === "/aboutus" ? (
                      <Nav.Link href="/contactus ">
                        <a className="nav-link special-btn" href="/contactus">
                          <button
                            className="btn my-2 my-sm-0 px-md-4 px-3 py-2"
                            type="submit"
                          >
                            Get a Quote Today!
                          </button>
                        </a>
                      </Nav.Link>
                    ) : (
                      <Nav.Link href="#footer">
                        <a className="nav-link special-btn" href="#footer">
                          <button
                            className="btn my-2 my-sm-0 px-md-4 px-3 py-2"
                            type="submit"
                          >
                            Get a Quote Today!
                          </button>
                        </a>
                      </Nav.Link>
                    )
                  ) : (
                    <span className="nav-link special-btn">
                      <button
                        className="btn my-2 my-sm-0 px-md-4 px-3 py-2"
                        type="submit"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        Get a Quote Today!
                      </button>
                    </span>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>

      <QuotePopup modal={modal} setModal={setModal} />
    </div>
  );
}
