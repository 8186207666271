import React from "react";
import "./InteriorCommercial.css";
import process1 from "../Images/consultation.png";
import process2 from "../Images/conceptualize.png";
import Develop from "../Images/Develop.png";
import Execute from "../Images/construct.png";
import interiorbg2 from "../Images/Interiorbg2.png";
import IntCommProjects from "../Component/IntCommProjects";
import Gola from "../Images/circle.png";
import OtherFooter from "../Component/OtherFooter";

const InteriorCommercial = () => {
  return (
    <div>
      <div id="intcomhome">
        <div className="intcom_imgtext">
          <p>Interior</p>
          <div className="headings col-md-4 col-10 mx-auto">
            <h3 className="pt-3 pt-md-0">Commercial Services</h3>
          </div>
        </div>
      </div>

      <div className="intcom_para">
        <p>
          Welcome to our Interior Services, where we elevate your living spaces
          with our bespoke residential interior design services.
        </p>
        <p>
          At M’s Design Studio, the team of experts is dedicated to creating
          environments that seamlessly blend style and functionality,
          transforming houses into homes. We breathe life into spaces, making
          every nook reflect your lifestyle. Explore our residential interior
          design services and embark on a journey to a beautifully designed
          home.
        </p>
      </div>

      <div className="intcom_offer py-4 py-md-0">
        <p className="">
          Commercial Services <br />
          We Offer
        </p>
        <IntCommProjects />

        <p className="py-4 mb-0">
          Our Interior Design <br /> Process
        </p>
        <img src={interiorbg2} alt="" />
        <div className="res_design position-relative mt-4">
          <div className="design_process mb-md-5 mb-3 ">
            <img src={process1} alt="img" className="mb-4" />
            <div className="design_display ">
              <img src={Gola} alt="" className="gola d-block m-0 gola1" />
              <h4 className="mt-md-4 pt-md-5 mt-0 px-2 mx-2">Consultation</h4>
              <p className="px-3 mt-md-3 pt-2 pt-md-0">
                We delve into your aspirations, uncovering your unique style,
                needs, and desires to set the foundation for the design journey.
              </p>
            </div>
          </div>

          <div className="design_process mb-md-5 mb-3">
            <img src={process2} alt="img" className="mb-4" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-0 gola2" />
              <h4 className="mt-md-4 pt-md-5 mt-1 px-2 mx-2">Conceptualize</h4>
              <p className="px-3 mt-md-3 pt-2 pt-md-0">
                Our creative team brings your vision to life through captivating
                concepts, weaving together colors, textures, and forms into
                inspiring designs.
              </p>
            </div>
          </div>

          <div className="design_process mb-md-5 mb-3">
            <img src={Develop} alt="img" className="mb-4" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-0 gola3" />
              <h4 className="mt-md-4 pt-md-5 mt-0 px-2 mx-2">Develop</h4>
              <p className="px-3 mt-md-3 pt-2 pt-md-0">
                With your feedback, concepts evolve into detailed plans, with
                precise layouts and carefully selected materials to resonates
                with your lifestyle.
              </p>
            </div>
          </div>

          <div className="design_process mb-md-5 mb-3">
            <img src={Execute} alt="" className="mb-5" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-0 gola4" />
              <h4 className="mt-md-5 pt-md-5 mt-1 px-0 mx-3 deskSpecial">
                Execute
              </h4>
              <p className="px-3 mt-md-3 pt-2 pt-md-0">
                Our team collaborate seamlessly to turn designs into reality,
                adding the final touches that transform your space into a
                personalized masterpiece.
              </p>
            </div>
          </div>

          <div className="container position-absolute arch-des-proc hr-intRes">
            <hr className="" />
          </div>
        </div>
      </div>

      <div id="footer">
        <OtherFooter />
      </div>
    </div>
  );
};

export default InteriorCommercial;
