import React from "react";
import "./Header.css";
import "./LogoFooter.css"

const LogoFooter = () => {
  const path = window.location.pathname
  return (
    <div className={`${path === '/aboutus' || path === '/contactus' ? 'footCustColor' : 'foot-color-primary'}`}>
      <div className="navbar sticky-top navbar-light  navbar-expand-md ">
        <div className="container logo d-flex flex-wrap justify-content-between ">
          <span className="navbar-brand logo ">
            <img
              src={require("../Images/mainLogo.png")}
              alt="logo"
              className="w-100"
            />
          </span>
          <hr
            className="d-md-none"
            style={{ height: "1px", width: " 100%", opacity: "0.15" }}
          />
          <div className="d-flex justify-content-center align-item-center">
            <div className="d-flex  justify-content-around">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0 ">
                <div className="footer ">
                  <div className="nav_footer align-items-start flex-wrap justify-content-start ">
                    <li className="nav-item px-md-3 px-5 foldNavStyl">
                      <a className="nav-link" href="/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item px-md-3 px-5 foldNavStyl">
                      <a className="nav-link " href="/archiresidential">
                        Architecture
                      </a>
                    </li>
                    <li className="nav-item px-md-3 px-5 foldNavStyl">
                      <a className="nav-link" href="/interiorresidential">
                        Interiors
                      </a>
                    </li>
                  </div>
                  <div className="nav_footer align-items-start flex-wrap justify-content-start ">
                    <li className="nav-item px-md-3 ps-4 pe-5 foldNavStyl">
                      <a className="nav-link" href="/turnkey">
                        Turnkey
                      </a>
                    </li>
                    <li className="nav-item px-md-3 ps-4 pe-5 foldNavStyl">
                      <a className="nav-link " href="/aboutus">
                        About Us
                      </a>
                    </li>
                    <li className="nav-item px-md-3 ps-4 pe-5 foldNavStyl">
                      <a className="nav-link " href="/contactus">
                        Contact Us
                      </a>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoFooter;
