import React from "react";
import "./Trunkey.css";
import intdesign from "../Images/intdesign.png";
import process1 from "../Images/Develop.png";
import process2 from "../Images/plan.png";
import process3 from "../Images/trunkconstruct.png";
import process4 from "../Images/trunkdelivery.png";
import Gola from "../Images/circle.png";
import TrunkeyProjects from "../Component/TrunkeyProjects";
import OtherFooter from "../Component/OtherFooter";

const Trunkey = () => {
  return (
    <div>
      <div id="hometrunkey" className="img-fluid">
        <div className="trunkey_imgtext">
          <p className="text-center">Other</p>
          <div className="headings col-md-4 col-10 mx-auto">
            <h3 className="pt-3 pt-md-0">Turnkey Services</h3>
          </div>
        </div>
      </div>

      <div className="trunkey_para">
        <p>
          Welcome to our Turnkey Contracting Services, where we transform
          visions into tangible structure turnkey, embodying exceptional design
          and innovation.
        </p>
        <p>
          At M’s Design Studio, we offer comprehensive turnkey contracting
          solutions designed to simplify your project's journey from concept to
          completion. Our expertise spans across various sectors, including
          turnkey residential, commercial, industrial, and institutional,
          delivering tailored solutions to meet your unique requirements.
        </p>
      </div>

      <div className="trunkey_offer">
        <p className="pt-4 headP">
          Turnkey Services We <br />
          Offer
        </p>
        <TrunkeyProjects />
        <p className="p-4 headP">Our Turnkey Process</p>

        <img src={intdesign} alt="" className="mob-height" />
        <div className="residential_design position-relative">
          <div className="design_process trunSpc px-md-4">
            <img src={process1} alt="" className="mb-md-5 mb-5 pt-2" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-0 gola1" />
              <h4 className="desh4 mt-3 mt-md-5 mx-md-3 ">Assessment</h4>
              <p className="ps-md-3 pe-md-0 px-2 pt-0 pt-md-2 trunk-spl">
                We start by understanding your project's goals, budget, and
                timelines. This initial assessment forms the basis of our
                proposal.
              </p>
            </div>
          </div>

          <div className="design_process trunSpc">
            <img src={process2} alt="" className="mb-md-5 mb-5 pt-2" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-0 gola2" />
              <h4 className="desh4 mt-3 mt-md-5 mx-md-3 ">Plan</h4>
              <p className="ps-md-3 pe-md-0 px-2 pt-0 pt-md-2 ">
                Our team collaborates with you to create a comprehensive design
                and project plan. We prioritize functionality, aesthetics, and
                cost-efficiency.
              </p>
            </div>
          </div>

          <div className="design_process trunSpc">
            <img src={process3} alt="" className="mb-md-5 mb-5 pt-2" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-0 gola3" />
              <h4 className="desh4 mt-md-5 mt-4 mx-md-3 ">Construction</h4>
              <p className="ps-md-3 pe-md-0 px-2 pt-0 pt-md-2 ">
                Skilled professionals take over to bring your project to life,
                managing all aspects of construction, from procurement to site
                management.
              </p>
            </div>
          </div>

          <div className="design_process trunSpc">
            <img src={process4} alt="" className="mb-md-5 mb-5 pt-2 " />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-0 gola4" />
              <h4 className="desh4 mt-3 mt-md-5 mx-2 ">Delivery</h4>
              <p className="px-md-2 px-2 pt-0 pt-md-2 ">
                Every project undergoes strict quality checks to ensure it
                aligns with your vision and our standards. We then hand over a
                completed project that's ready for use.
              </p>
            </div>
          </div>

          <div className="container position-absolute arch-des-proc">
            <hr className="" />
          </div>
        </div>
      </div>

      <div id="footer">
        <OtherFooter />
      </div>
    </div>
  );
};

export default Trunkey;
