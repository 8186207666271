import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./OtherFooter.css";

const OtherFooter = () => {
  const [formData, setformData] = useState({});

  // const location = useLocation();
  // const path = location.pathname;

  const handleChange = (event) => {
    const { name, value } = event.target;
    let nameValue = name;
    let valueValue = value;
    setformData({ ...formData, [nameValue]: valueValue });

    if (nameValue === "name") {
      const errorElement = document.getElementById("nameError");
      errorElement.textContent =
        valueValue.trim() === "" ? "Name is required" : "";
    } else if (nameValue === "contactNo") {
      const contactNoPattern = /^\d{10}$/;
      const errorElement = document.getElementById("contactNoError");
      errorElement.textContent = !contactNoPattern.test(valueValue)
        ? "Invalid contact number (10 digits required)"
        : "";
    } else if (nameValue === "service") {
      const errorElement = document.getElementById("dropdownError");
      errorElement.textContent = "";
    } else if (nameValue === "TentativeBudget") {
      const errorElement = document.getElementById("TentativeBudgetError");
      errorElement.textContent = "";
    } else if (nameValue === "AppartmentType") {
      const errorElement = document.getElementById("apartmentdropdownError");
      errorElement.textContent = "";
    } else if (nameValue === "city") {
      const errorElement = document.getElementById("cityError");
      errorElement.textContent =
        valueValue.trim() === "" ? "City is required" : "";
    }
  };

  const submitForm = (e) => {
    const config = {
      SecureToken: process.env.REACT_APP_SECURITY_TOKEN,
      To: process.env.REACT_APP_EMAIL_ID,
      From: process.env.REACT_APP_FROM_EMAIL_ID,
      Subject: "New enquiry received to Book a Design Session",
      Body: `
              Full Name : ${formData.name} <br />
              Phone Number : ${formData.contactNo} <br />
              Service : ${formData.service} <br />
              Appartment Type : ${formData.AppartmentType} <br />
              Tentative Budget : ${formData.TentativeBudget} <br />
              City : ${formData.city}
          `,
    };

    if (
      formData.service &&
      formData.AppartmentType &&
      formData.TentativeBudget
    ) {
      if (window.Email) {
        window.Email.send(config)
          .then((res) => {
            document.getElementById("footer_form").reset();
            // console.log(res);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            toast.success(
              "Thank you for reaching out to us. We have received your message. We will get back to you soon.",
              {
                position: "top-right", // Toast position
                autoClose: 2500, // Duration in milliseconds
                hideProgressBar: false, // Whether to display a progress bar
                closeOnClick: true, // Close toast on click
                pauseOnHover: true, // Pause the autoClose timer on hover
                draggable: true, // Enable dragging to dismiss the toast
                progress: undefined, // Custom progress bar component
              }
            );
          })
          .catch((err) => {
            // console.log(err);
            toast.error("Opps! Something went wrong.", {
              position: "top-right", // Toast position
              autoClose: 2500, // Duration in milliseconds
              hideProgressBar: false, // Whether to display a progress bar
              closeOnClick: true, // Close toast on click
              pauseOnHover: true, // Pause the autoClose timer on hover
              draggable: true, // Enable dragging to dismiss the toast
              progress: undefined, // Custom progress bar component
            });
          });
      }
    } else {
      if (!formData.service) {
        const errorElement = document.getElementById("dropdownError");
        errorElement.textContent = "Please select a service";
      }
      if (!formData.AppartmentType) {
        const errorElement = document.getElementById("apartmentdropdownError");
        errorElement.textContent = "Please select a Type";
      }
      if (!formData.TentativeBudget) {
        const errorElement = document.getElementById("TentativeBudgetError");
        errorElement.textContent = "Please select a Tentative Budget";
      }
    }
  };

  return (
    <div>
      <div className="App">
        <div className="footer-bg p-3">
          <div className="container d-flex flex-wrap justify-content-between align-items-center col-md-12">
            <div className=" mt-5 col-md-7 footer-design">
              <h1 className="text-left fs-2">Meet Our Designer</h1>
              <p className="text-left mt-4 fs-5">
                M's Design Studio provides you with superior consultation
                services, connecting you with our top designers who truly
                understand your needs and work diligently to provide solutions
                that turn your dreams into reality.
              </p>
              <p className="text-left fs-5">
                Book a Design Session today. It's FREE!
              </p>
            </div>

            <form
              className="footer_form col-md-4 mt-md-0 mt-4 p-3"
              onSubmit={(e) => {
                submitForm(e);
                e.preventDefault();
              }}
              id="footer_form"
            >
              {/* <label htmlFor="name" className="contact-form-label">
                {" "}
                Name{" "}
              </label> */}
              <input
                required
                className="contact-form-input  mt-3 pt-0"
                type="text"
                onChange={handleChange}
                name="name"
                placeholder="Name"
              />
              <small className="error" id="nameError"></small>
              {/* 
              <label htmlFor="contact-no" className="contact-form-label mt-4">
                Contact Number{" "}
              </label> */}
              <input
                required
                className="contact-form-input mt-4 pt-0"
                type="tel"
                minLength={10}
                maxLength={10}
                onChange={handleChange}
                name="contactNo"
                placeholder="Contact Number"
              />
              <small className="error" id="contactNoError"></small>

              {/* <label htmlFor="service" className="contact-form-label mt-3">
                  Service
                </label> */}
              <select
                required
                className={`${
                  formData.service ? "" : "selectPlaceholder"
                } contact-form-input py-2 mt-4 pt-2`}
                type="text"
                onChange={handleChange}
                name="service"
              >
                <option selected disabled>
                  Select a Service
                </option>
                <option>Architecture</option>
                <option>Interior</option>
                <option>Turnkey</option>
              </select>
              <small className="error" id="dropdownError"></small>

              <select
                required
                className={`${
                  formData.AppartmentType ? "" : "selectPlaceholder"
                } contact-form-input py-2 mt-3 pt-3`}
                type="text"
                onChange={handleChange}
                name="AppartmentType"
              >
                <option selected disabled>
                  Apartment type
                </option>
                <option>Flat - 2BHK</option>
                <option>Flat - 3/4 BHK</option>
                <option>Bunglow/Villa</option>
              </select>
              <small className="error" id="apartmentdropdownError"></small>

              <select
                required
                className={`${
                  formData.TentativeBudget ? "" : "selectPlaceholder"
                } contact-form-input py-2 mt-3 pt-3`}
                type="text"
                onChange={handleChange}
                name="TentativeBudget"
              >
                <option selected disabled>
                  Tentative Budget
                </option>
                <option>Below 5 lakhs</option>
                <option>5 lakhs to 10 Lakhs</option>
                <option>10 lakhs to 50 lakhs</option>
                <option>50 Lakhs and Above</option>
              </select>
              <small className="error" id="TentativeBudgetError"></small>
              {/* 
              <select
                required
                className={`${
                  formData.Area ? "" : "selectPlaceholder"
                } contact-form-input py-2 mt-3 pt-3`}
                type="text"
                onChange={handleChange}
                name="Area"
              >
                <option selected disabled>
                  Area
                </option>
                <option>Square Foot</option>
                <option>Square Meter</option>
              </select>
              <small className="error" id="AreaError"></small> */}

              <input
                required
                className="contact-form-input mt-3 pt-2"
                type="text"
                onChange={handleChange}
                name="city"
                placeholder="Enter Your City"
              />
              <small className="error" id="cityError"></small>

              <div className="center">
                <button
                  type="submit"
                  className="contact-fornm-btn mt-3 "
                  value="Book a Design Session"
                >
                  Book a Design Session
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherFooter;
