import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ContactUs.css";

const ContactUs = () => {
  const [formData, setformData] = useState({});
  // const validDropdownOptions = ["Architecture", "Interiors", "Trunkey"];
  const handleChange = (event) => {
    const { name, value } = event.target;
    let nameValue = name;
    let valueValue = value;
    setformData({ ...formData, [nameValue]: valueValue });

    if (nameValue === "name") {
      const errorElement = document.getElementById("nameError");
      errorElement.textContent =
        valueValue.trim() === "" ? "Name is required" : "";
    } else if (nameValue === "serviceDropdown") {
      const errorElement = document.getElementById("dropdownError");
      errorElement.textContent = "";
    } else if (nameValue === "contactNo") {
      const contactNoPattern = /^\d{10}$/;
      const errorElement = document.getElementById("contactNoError");
      errorElement.innerHTML = !contactNoPattern.test(valueValue)
        ? "Invalid contact number (10 digits required)"
        : "";
    } else if (nameValue === "emailId") {
      // Validate 'email' field (e.g., it should be a valid email address)
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const errorElement = document.getElementById("emailError");
      errorElement.innerHTML = !emailPattern.test(valueValue)
        ? "Invalid email address"
        : "";
    } else if (nameValue === "message") {
      const errorElement = document.getElementById("messageError");
      errorElement.textContent =
        valueValue.trim() === "" ? "Message is required" : "";
    }
  };

  const submitForm = (e) => {
    const config = {
      SecureToken: process.env.REACT_APP_SECURITY_TOKEN,
      To: process.env.REACT_APP_EMAIL_ID,
      From: formData.emailId,
      Subject: "New enquiry received from Contact Us",
      Body: `
            Full Name : ${formData.name} <br />
            Email Id : ${formData.emailId} <br />
            Phone Number : ${formData.contactNo} <br />
            Message : ${formData.message}
        `,
    };

    if(formData.serviceDropdown){
      if (window.Email) {
        window.Email.send(config)
          .then((res) => {
            document.getElementById("contactForm").reset();
            setTimeout(() => {
              window.location.reload()
            }, 2000);
            toast.success(
              "Thank you for reaching out to us. We have received your message. We will get back to you soon",
              {
                position: "top-right", // Toast position
                autoClose: 2500, // Duration in milliseconds
                hideProgressBar: false, // Whether to display a progress bar
                closeOnClick: true, // Close toast on click
                pauseOnHover: true, // Pause the autoClose timer on hover
                draggable: true, // Enable dragging to dismiss the toast
                progress: undefined, // Custom progress bar component
              }
            );
          })
          .catch((err) => {
            toast.error("Opps! Something went wrong.", {
              position: "top-right", // Toast position
              autoClose: 2500, // Duration in milliseconds
              hideProgressBar: false, // Whether to display a progress bar
              closeOnClick: true, // Close toast on click
              pauseOnHover: true, // Pause the autoClose timer on hover
              draggable: true, // Enable dragging to dismiss the toast
              progress: undefined, // Custom progress bar component
            });
          });
      }
    } else {
      const errorElement = document.getElementById("dropdownError");
      errorElement.textContent = "Please select service";
    }
  };

  return (
    <div className="contacthome">
      <div className=" text-center contact_main">
        <ToastContainer />
        <img
          src={require("../Images/contactbg.png")}
          className="img-fluid w-100 contactbg"
          alt="contactbg"
        />
        <div className=" w-100 text-center"></div>

        <div class="container col-md-9 col-sm-12 " id="footer">
          <div class="container  row">
            <div class="col-md-9">
              <div class="image-container">
                <img
                  src={require("../Images/headcontact.png")}
                  alt="contactbg"
                  className="img-fluid"
                />
                <div class="text-overlay">
                  <h2>
                    Contact us for Design <br />
                    Inquiries
                  </h2>
                </div>
              </div>
            </div>

            <div className=" container col-md-12 rounded-top rounded-bottom mt-3 pt-3 pb-5 contact_display ms-2 ms-md-0">
              <p>
                Contact us today for any inquiries or to schedule a
                consultation.
              </p>
              <div className=" container col-md-12 contact_border">
                <div className=" col-md-6 col-sm-12 location">
                  <div className="col-md-8 p-3 rounded-top rounded-bottom contactbox">
                    <h4>Contact Info:</h4>
                    <p> </p>
                    <p>
                      Phone: &nbsp;
                      <a className="text-black text-decoration-none" href="tel:9545653435">
                        +91 9545653435
                      </a>
                    </p>
                    <p>
                      Email: &nbsp;
                      <a
                        className="text-black text-decoration-none eMail"
                        href="mailto:mdesignstudio.ind@gmail.com"
                      >
                        mdesignstudio.ind@gmail.com
                      </a>
                    </p>
                    <p>
                      Address: B-49, 5th Floor, K K Market Mall, Dhankwadi, Pune
                      -411043
                    </p>
                  </div>
                  <div className=" col-md-8 mt-3">
                    <h4>Locate Us:</h4>
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          title="Map"
                          className="gmap_iframe"
                          width="100%"
                          src="https://www.google.com/maps/embed/v1/place?q=M's+Design+Studio,+KK+Market,+Mall,+Pune,+Maharashtra,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                        />
                        <a href="https://connectionsgame.org/">
                          Connections NYT
                        </a>
                      </div>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            ".mapouter{position:relative;text-align:right;width:100%;height:250px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:250px;}.gmap_iframe {height:250px!important;}",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <form
                  className="col-md-5 mt-4 mt-md-0 form-contact"
                  onSubmit={(e) => {
                    submitForm(e);
                    e.preventDefault();
                  }}
                  id="contactForm"
                >
                  <div className="form-head mt-2"></div>

                  <label for="name" className="contact-form-label">
                    Name
                  </label>
                  <input
                    required
                    className="contact-form-input  "
                    type="text"
                    onChange={handleChange}
                    name="name"
                  />
                  <small className="error left-aligned" id="nameError"></small>

                  <label for="contact-no" className="contact-form-label mt-4">
                    Contact Number
                  </label>
                  <input
                    // required
                    className="contact-form-input "
                    type="tel"
                    minLength={10}
                    maxLength={10}
                    onChange={handleChange}
                    name="contactNo"
                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  />
                  <small
                    className="error left-aligned"
                    id="contactNoError"
                  ></small>
                  <label for="contact-no" className="contact-form-label mt-4">
                    Email
                  </label>
                  <input
                    required
                    className="contact-form-input mb-2"
                    type="email"
                    onChange={handleChange}
                    name="emailId"
                  />
                  <small className="error left-aligned" id="emailError"></small>
                  {/* <label for="service" className="contact-form-label mt-4">
                  </label> */}
                  <select
                    required
                    className={`${formData.serviceDropdown ? '' : 'selectPlaceholder'} contact-form-input mt-4 mb-2 pb-2`}
                    onChange={handleChange}
                    name="serviceDropdown"
                  >
                    <option selected disabled>Select a Service</option>
                    <option>Architecture</option>
                    <option>Interiors</option>
                    <option>Turnkey</option>
                  </select>
                  <small
                    className="error left-aligned"
                    id="dropdownError"
                  ></small>
                  <label for="message" className="contact-form-label mt-4 pb-1">
                    Message
                  </label>
                  <textarea
                    required
                    className="contact-form-textarea p-3"
                    placeholder="Type your message here!"
                    onChange={handleChange}
                    type="text"
                    rows={4}
                    name="message"
                  />
                  <small
                    className="error left-aligned"
                    id="messageError"
                  ></small>
                  <div className="center">
                    <button
                      type="submit"
                      className="contact-forn-btn mt-3 "
                      value="Contact Us"
                    >
                      Contact Us
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        

      </div>
    </div>
  );
};

export default ContactUs;
