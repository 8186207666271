import React from "react";
import "./Footer.css";
import "./LogoFooter.css"

const Footer = () => {
  const path = window.location.pathname
  return (
    <div className={`${path === '/aboutus' || path === '/contactus' ? 'footCustColor mainfooter' : 'foot-color-primary mainfooter'}`}>
      <div className="container">
        <hr className=" w-100 mt-0 mb-0 d-none d-md-block" />
      </div>

      <footer className="navbar  sticky-top navbar-light  navbar-expand-md d-md-none">
        <div className="container footer d-flex flex-wrap justify-content-md-between align-items-start px-5 px-md-0">
          <span className="d-none d-md-block ">2023©Copyright Reserved</span>
          <div className="d-flex  footer flex-row justify-content-center align-item-center">
            <div className="d-flex justify-content-around mt-2">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0 ">
                <div className="container d-flex ">
                  <li className="nav-item active ">
                    <a
                      className="nav-link"
                      href="https://www.facebook.com/mdesignstudio.ind"
                      target="_blank"
                      rel="norel noreferrer"
                    >
                      <img
                        src={require("../Images/facebook.png")}
                        alt="facebook"
                        className="img-fluid icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item active px-1 ps-3">
                    <a
                      className="nav-link"
                      href="https://instagram.com/m_design_studio__?utm_source=qr&igshid=MzNlNGNkZWQ4Mg=="
                      target="_blank"
                      rel="norel noreferrer"
                    >
                      <img
                        src={require("../Images/instagram.png")}
                        alt="instagram"
                        className="img-fluid"
                      />
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <span className="d-md-none px-2 mt-4">2023©Copyright Reserved</span>
        </div>
      </footer>
      <footer className="navbar  sticky-top navbar-light  navbar-expand-md ">
        <div className="container  justify-content-between  px-md-0">
          <span className="d-none d-md-block ps-3">2023 &copy; Copyright Reserved</span>
          <div className="d-flex   justify-content-center ">
            <div className="d-flex justify-content-around mt-2">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0 d-none d-md-block">
                <div className="container d-flex ">
                  <li className="nav-item active ">
                    <a
                      className="nav-link"
                      href="https://www.facebook.com/mdesignstudio.ind"
                      target="_blank"
                      rel="norel noreferrer"
                    >
                      <img
                        src={require("../Images/facebook.png")}
                        alt="facebook"
                        className="img-fluid icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item active px-1 ps-3">
                    <a
                      className="nav-link"
                      href="https://instagram.com/m_design_studio__?utm_source=qr&igshid=MzNlNGNkZWQ4Mg=="
                      target="_blank"
                      rel="norel noreferrer"
                    >
                      <img
                        src={require("../Images/instagram.png")}
                        alt="instagram"
                        className="img-fluid"
                      />
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
