import React, { useState } from "react";
import "./Project.css";
import Popup from "./Popup";
import { HomeInterior } from "./Items";

export default function HomeInteriorProjects() {
  const [modal, setModal] = useState(false);
  const [currentData, setCurrentData] = useState();

  return (
    <div className="">
      <div className="container d-flex flex-wrap justify-content-center  ">
        {HomeInterior.slice(0)
          .map((data, i) => {
            return i % 2 === 0 ? (
              <div
                className="col-md-6 position-relative p-3 projj"
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModal(true);
                  setCurrentData(data);
                }}
              >
                <img
                  src={data.img}
                  alt="Project Img"
                  className="img-fluid w-100 h-100 projImg"
                />
                <div className="project-text position-absolute w-100">
                  <h1>{data.heading}</h1>
                </div>
              </div>
            ) : (
              <div
                className="col-md-6 position-relative p-3 "
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModal(true);
                  setCurrentData(data);
                }}
              >
                <img
                  src={data.img}
                  alt="Project Img"
                  className="img-fluid w-100 h-100 projImg "
                />
                <div className="project-text text-center position-absolute w-100">
                  <h1>{data.heading}</h1>
                </div>
              </div>
            );
          })}

        <Popup modal={modal} setModal={setModal} currentData={currentData} />
      </div>
    </div>
  );
}
