import React, { useState } from "react";
import "./ImageSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

export default function ImageSlider({ popupImg }) {

  const para1 = document.getElementById("paragraph-1");
  const para2 = document.getElementById("paragraph-2");
  const subHeading = document.getElementById("subHeading");

  const handleClick = (data) => {
    // console.log(data);
    para1.innerText = data.para1;
    para2.innerText = data.para2;
    subHeading.innerText = data.title;
  };

  const [mainSlider, setMainSlider] = useState(null)


  return (
    <>
      <div>
        {/* <img
          src={popupImg && popupImg[0].pop}
          alt="Main Img"
          id="main-img"
          style={{ height: "auto" }}
          className="img-fluid w-100 p-2"
        /> */}
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          navigation={{ clickable: true }}
          onInit={(ev) => {
            setMainSlider(ev)
          }}
          // onSlideChange={() => {setMainSlider(popupImg); console.log(mainSlider);}}
        >
          {
            popupImg.map((data, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="container w-100 mt-3 p-2 ">
                    <div>
                      <img
                        src={data.pop}
                        alt=""
                        className=" img-fluid w-100 "
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={4}
        autoplay={{ delay: 5000 }}
        navigation={{ clickable: true }}
      >
        {popupImg.length > 1 &&
          popupImg.map((data, i) => {
            // console.log('check data',data);
            return (
              <SwiperSlide key={i}>
                <div className="container w-100 mt-3 p-2 imgSize">
                  <div className="pop-head">
                    <img
                      src={data.pop}
                      alt=""
                      className=" img-fluid w-100 pop-img-height"
                      onClick={() => {
                        mainSlider.slideTo(i);
                        handleClick(data);
                      }}
                    />
                    <p style={{ color: "gray" }} className={data.textIssue < 480 ? 'false-font' : ''}>
                      {data.title}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div className="container">
        <h5 style={{ color: "orange" }} id="title">
          {popupImg && popupImg[0].subHeading}
        </h5>
        <h2 id="subHeading">{popupImg && popupImg[0].title}</h2>
        <p id="paragraph-1">{popupImg && popupImg[0].para1}</p>
        <p id="paragraph-2">{popupImg && popupImg[0].para2}</p>
      </div>
    </>
  );
}
