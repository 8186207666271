export const ArchResItems = [
  {
    id: 1,
    img: require("../Images/bunglows.png"),
    heading: "Bungalows",
    pophead: "Residential < Bungalows",
    popImg: [
      {
        id: 1,
        title: "Bungalows",
        subHeading: "Residential",

        pop: require("../Compress Img/Architecture-residential/Arc-Res-Bungalow1.png"),

        para1:
          "Dive into the world of luxury living with our first bungalow design. We craft spacious, elegant, and functional layouts that redefine modern living. ",
        para2:
          "From grand facades to meticulously designed interiors, this option ensures your bungalow is a sanctuary of style and comfort.",
      },
      {
        id: 2,
        title: "Bungalows",
        subHeading: "Residential",

        pop: require("../Compress Img/Architecture-residential/Arc-Res-Bungalow2.png"),

        para1:
          "Embrace contemporary living with our bungalow design. We focus on open spaces, natural light, and sustainability. ",
        para2:
          "This option offers versatile layouts and eco-friendly features, catering to those who seek a balance between modernity and environmental consciousness.",
      },
      {
        id: 3,

        title: "Bungalows",
        subHeading: "Residential",

        pop: require("../Compress Img/Architecture-residential/Arc-Res-Bungalow3.png"),

        para1:
          "Discover timeless charm in our bungalow design. We create classic yet practical spaces that evoke a sense of nostalgia. ",
        para2:
          "From charming verandas to cozy interiors, this option ensures your bungalow exudes warmth and traditional appeal.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/building.png"),
    heading: "Buildings",
    pophead: "Residential < Buildings",
    popImg: [
      {
        id: 1,
        title: "Buildings",
        subHeading: "Residential",
        pop: require("../Compress Img/Architecture-residential/Arc-Res-Building1.png"),
        para1:
          "Explore urban living with our first residential building design. We specialize in creating efficient, multi-story structures that maximize space and convenience.           ",
        para2:
          "From contemporary facades to well-planned interiors, this option offers modern living solutions that are both practical and stylish.",
      },
      {
        id: 2,
        title: "Buildings",
        subHeading: "Residential",
        pop: require("../Compress Img/Architecture-residential/Arc-Res-Building2.png"),

        para1:
          "Elevate city living with our residential building design. We prioritize sustainable, high-rise structures that offer panoramic views and green features. ",
        para2:
          "This option caters to those who desire urban living with a focus on environmental responsibility.",
      },
    ],
  },
];

export const ArchCommItems = [
  {
    id: 1,
    img: require("../Images/Shop.png"),
    heading: "Shops",
    pophead: "Commercial < Shop",
    popImg: [
      {
        id: 1,
        title: "Shops",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Shop1.png"),
        para1:
          "Transform retail with our first shop design. We specialize in crafting inviting, customer-centric spaces.  ",
        para2:
          "From eye-catching storefronts to intuitive layouts, this option ensures your shop is a magnet for foot traffic, enhancing the retail experience.",
      },
      {
        id: 2,
        title: "Shops",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Shop2.png"),
        para1:
          "Unleash modernity in retail with our shop design. We focus on innovative layouts and digital integration. ",
        para2:
          "This option caters to those who seek cutting-edge retail solutions that combine physical and online shopping seamlessly.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/Industrial.png"),
    heading: "Industrial",
    pophead: "Commercial < Industrial",
    popImg: [
      {
        id: 1,
        title: "Industrial",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Industrial1.png"),
        para1:
          "Optimize industrial spaces with our design choice. We specialize in creating efficient layouts that prioritize workflow and safety. ",
        para2:
          "From high-capacity storage solutions to streamlined production floors, this option ensures your industrial facility operates with maximum efficiency.",
      },
      {
        id: 2,
        title: "Industrial",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Industrial2.png"),

        para1:
          "Embrace innovation in industry with our design. We focus on sustainability and automation, offering eco-friendly designs and smart factory integration. ",
        para2:
          "This option caters to those who seek future-ready industrial solutions that reduce environmental impact and enhance productivity. ",
      },
    ],
  },

  {
    id: 3,
    img: require("../Images/Hospital.png"),
    heading: "Hospital/Clinics",
    pophead: "Commercial < Hospital/Clinics",
    popImg: [
      {
        id: 1,
        title: "Hospital / Clinics",
        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Hospital1.png"),
        subHeading: "Commercial",

        para1:
          "Elevate healthcare with our hospital design. We specialize in patient-centric layouts that prioritize comfort and efficiency. ",
        para2:
          "From soothing patient rooms to state-of-the-art surgical suites, this option ensures your hospital is a haven for healing and well-being.",
      },
      {
        id: 2,
        title: "Hospital / Clinics",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Hospital2.png"),

        para1:
          " We specialize in creating inviting, specialized spaces. From welcoming reception areas to well-organized examination rooms, ",
        para2:
          " this option ensures your clinic offers a seamless and comfortable patient experience.",
      },
      {
        id: 3,
        title: "Hospital / Clinics",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Hospital3.png"),
        para1:
          "Embrace modern healthcare practices with our redefined designs. We prioritize efficiency and digital integration, offering telehealth solutions and streamlined patient management.",
        para2:
          " This option caters to those who seek progressive clinic spaces that embrace technology and optimize patient care.",
      },
    ],
  },

  {
    id: 4,
    img: require("../Images/Offices.png"),
    heading: "Offices",
    pophead: "Commercial < Offices",
    popImg: [
      {
        id: 1,
        title: "Offices",
        subHeading: "Commercial",
        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Office1.png"),
        para1:
          "Transform your office spaces with our design choice. We specialize in creating dynamic work environments that foster creativity and productivity.          ",
        para2:
          "From open workspaces to ergonomic meeting rooms, this option ensures your office becomes a hub of innovation and collaboration. ",
      },
      {
        id: 2,
        title: "Offices",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Office2.png"),

        para1:
          " We focus on adaptability and sustainability, offering flexible layouts and eco-conscious designs. ",
        para2:
          " This option caters to those who seek versatile office spaces that can evolve with their business needs while minimizing environmental impact.",
      },
    ],
  },
];

export const IntResItems = [
  {
    id: 1,
    img: require("../Images/Entry.png"),
    heading: "Entry",
    pophead: "Residential < Entry",
    popImg: [
      {
        id: 1,
        title: "Shoe Rack",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Entry-Shoerack.png"),
        para1:
          "Our shoe rack designs are more than just storage; they are a blend of functionality and elegance. We craft custom solutions that not only keep your footwear organized but also enhance your entry area's visual appeal.",
        para2:
          "Say goodbye to cluttered hallways, and welcome a neater, more inviting space with our shoe rack designs.",
      },
      {
        id: 2,
        title: "Safety Door",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Entry-Safetydoor1.png"),

        para1:
          "Safety meets elegance with our Safety Door designs. We prioritize your family's security without compromising on aesthetics. ",
        para2:
          "Our safety doors are crafted with precision and attention to detail, ensuring they complement your home's overall look while providing top-notch protection. Your peace of mind is our design inspiration.",
      },
      {
        id: 3,

        title: "Safety Door",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Entry-Safetydoor2.png"),

        para1:
          "Your safety is our priority, and our safety door designs reflect that commitment. We engineer sturdy, elegant doors that provide security without compromising on elegance.",
        para2:
          " With a range of styles and finishes, we ensure your home's first line of defense complements your unique taste.",
      },
      {
        id: 4,
        title: "Passage / Foyer",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Entry-Foyer1.png"),

        para1:
          "We create a stunning first impression with our entryway designs. We conceptualize welcoming spaces that set the tone for your home, combining aesthetics with practicality. ",
        para2:
          "From stylish foyer furniture to clever storage solutions, we infuse practicality with aesthetics, ensuring your entryway is a reflection of your unique style. Whether it's a grand entrance or a cozy nook, we transform this space into a statement of warmth and sophistication.",
      },
      {
        id: 5,
        title: "Passage / Foyer",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Entry-Foyer2.png"),

        para1:
          "Foyers are the first impression of your home, and our designs make it unforgettable. We create welcoming spaces that set the tone for your unique style. ",
        para2:
          "From stylish foyer furniture to clever storage solutions, we infuse practicality with aesthetics, ensuring your entryway is a reflection of warmth and sophistication. Make your entrance memorable with our Foyer designs.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/Living Room.png"),
    heading: "Living Room",
    pophead: "Residential < Living Room",
    popImg: [
      {
        id: 1,
        title: "Modern Theme",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Modern1.png"),
        para1:
          "Our Modern Theme for living rooms redefines contemporary luxury. We craft spaces that embody sleek lines, minimalism, and functionality. With an emphasis on clean design,",
        para2:
          " we create living rooms that exude sophistication and comfort. Elevate your living experience with our Modern Theme designs.",
      },
      {
        id: 2,
        title: "Modern Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Modern2.png"),
        para1:
          "Our modern-themed living rooms exude contemporary elegance. We harmonize clean lines, neutral palettes, and minimalist design to create spaces that are both chic and comfortable. ",
        para2:
          "Discover the perfect balance between sophistication and relaxation in our modern living rooms.",
      },

      {
        id: 5,
        title: "French Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-French1.png"),

        para1:
          "Transport yourself to the romantic ambiance of Paris with our French Theme living room designs. We infuse elegance, charm, and a touch of vintage flair into your living space.",
        para2:
          " From ornate furniture to soft color palettes, our designs capture the essence of French style, bringing a timeless appeal to your home.",
      },
      {
        id: 6,
        title: "French Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-French2.png"),

        para1:
          "Experience the romance of Paris in your living room with our French-themed designs. We blend classic French aesthetics with modern comfort to transport you to a charming Parisian setting. ",
        para2:
          "From ornate details to plush furnishings, every element reflects the essence of France.",
      },
      {
        id: 7,
        subHeading: "Residential",

        title: "Indian Theme",
        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Indian1.png"),

        para1:
          "Our Indian Theme living room designs celebrate the rich heritage of India. We incorporate traditional elements like vibrant colors, intricate patterns, and handcrafted furniture to create a warm and inviting atmosphere. ",
        para2:
          "Immerse yourself in the beauty of Indian culture with our designs.",
      },
      {
        id: 8,
        title: "Indian Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Indian2.png"),

        para1:
          "Immerse yourself in the rich tapestry of Indian culture with our Indian-themed living rooms. We celebrate vibrant colors, intricate patterns, and traditional craftsmanship to bring the essence of India to your home.",
        para2:
          " Our designs are a tribute to the timeless beauty of Indian heritage.",
      },
      {
        id: 9,
        title: "Earthy Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Earthy1.png"),
        para1:
          "Embrace the serenity of nature with our Earthy Theme living room designs. We bring the outdoors inside by using natural materials, earthy tones, and sustainable furnishings. ",
        para2:
          "Our designs create a harmonious space that fosters relaxation and connection with the natural world.",
      },
      {
        id: 10,
        title: "Earthy Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Earthy2.png"),

        para1:
          "Connect with nature in our earthy-themed living rooms. We bring the outdoors inside with natural materials, earthy tones, and organic textures.",
        para2:
          " Our designs create serene, grounding spaces that allow you to unwind and recharge in the embrace of nature.",
      },
      {
        id: 11,
        title: "Retro Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Retro1.png"),

        para1:
          "Step back in time with our Retro Theme living room designs. We revive the iconic styles of the past, blending bold colors, funky patterns, and vintage furniture.",
        para2:
          " Our designs add a nostalgic charm and playful spirit to your living room, making it a conversation starter.",
      },
      {
        id: 12,
        title: "Retro Theme",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Retro2.png"),
        para1:
          "You witness the richness of colors in our retro-themed living rooms. We revive the nostalgia of bygone eras with bold colors, funky patterns, and vintage furniture. ",
        para2:
          "Our designs are a tribute to the timeless charm of the retro aesthetic.",
      },

      {
        id: 13,
        title: "Contemporary Theme",
        subHeading: "Residential",
        textIssue: "",
        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Contemporary1.png"),
        para1:
          "Our Contemporary Theme living room designs epitomize modern elegance. We focus on clean lines, neutral color palettes, and versatile furniture. ",
        para2:
          "With a touch of minimalism, our designs create a timeless and sophisticated living space that suits your evolving lifestyle.",
      },
      {
        id: 14,
        title: "Contemporary Theme",
        subHeading: "Residential",
        textIssue: "",
        pop: require("../Compress Img/Interior-residential/Int-re-Livingroom-Contemporary2.png"),
        para1:
          "Experience the best of modern design in our contemporary-themed living rooms. We push the boundaries of innovation, incorporating cutting-edge technology and avant-garde aesthetics. ",
        para2:
          "Our designs are a testament to the ever-evolving world of contemporary interior design.",
      },
    ],
  },

  {
    id: 3,
    img: require("../Images/Bedroom.png"),
    heading: "Bedroom",
    pophead: "Residential < Bedroom",
    popImg: [
      {
        id: 1,
        title: "Childern Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Children1.png"),
        para1:
          "Children's imaginations know no bounds, and our designs reflect their creativity. We craft fun and functional spaces where kids can play, learn, and dream. ",
        para2:
          "From vibrant colors to clever storage, our designs cater to every child's unique personality.",
      },
      {
        id: 2,
        title: "Childern Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Children2.png"),
        para1:
          " Spark your child's imagination with our children's bedroom designs. We infuse fun and functionality into every detail, from playful colors to smart storage solutions. ",
        para2:
          "Our designs grow with your child, adapting to their evolving needs and tastes.",
      },
      {
        id: 3,
        title: "Guest Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Guest1.png"),
        para1:
          "Welcome your guests with open arms and a cozy Guest Bedroom. Our designs prioritize comfort and functionality, offering a welcoming retreat for your visitors. ",
        para2:
          "From comfortable bedding to thoughtful amenities, we ensure your guests feel right at home.",
      },
      {
        id: 4,
        title: "Guest Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Guest2.png"),
        para1:
          "Greet your guests with inviting guest luxurious bedroom designs. We prioritize comfort and versatility, ensuring your guests feel at home.",
        para2:
          " Our designs seamlessly blend style and functionality to make every visit memorable.",
      },

      {
        id: 6,
        title: "Parent's Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Parent1.png"),
        para1:
          "Our Parent's Bedroom designs strike a balance between style and practicality. We create spaces that cater to the unique needs of parents, with ample storage, comfortable seating, and a tranquil ambiance..",
        para2: " Rest easy in a bedroom designed for your well-being",
      },
      {
        id: 7,
        title: "Parent's Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Parent2.png"),
        para1:
          "Create a sanctuary for parents with our thoughtful parent's bedroom designs. We understand the need for relaxation and privacy, and our designs cater to these essentials. ",
        para2: "Experience harmony and rejuvenation in every corner",
      },

      {
        id: 9,
        title: "Master Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Master1.png"),
        para1:
          "Your Master Bedroom should be your sanctuary, and our designs reflect that. We emphasize comfort, luxury, and personalization. .",
        para2:
          "From plush bedding to custom storage solutions, we create a haven where you can unwind and rejuvenate in style",
      },
      {
        id: 10,
        title: "Master Bedroom",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bedroom-Master2.png"),
        para1:
          "Unwind in luxury with our master bedroom designs. We craft serene retreats where comfort meets elegance. ",
        para2:
          "From opulent furnishings to soothing color palettes, we create a haven tailored to your desires.",
      },
    ],
  },

  {
    id: 4,
    img: require("../Images/Kitchen.png"),
    heading: "Kitchen",
    pophead: "Residential < Kitchen",
    popImg: [
      {
        id: 1,
        title: "L Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Lshape1.png"),
        para1:
          "Maximize space and efficiency with our L-shaped modular kitchen designs. We optimize every corner, making meal preparation a breeze. ",
        para2:
          "Our kitchens are a blend of functionality and aesthetics, transforming your cooking space into a culinary haven.",
      },
      {
        id: 2,
        title: "L Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Lshape2.png"),
        para1:
          " Experience culinary excellence with our L-shaped modular kitchen design. We've optimized every inch to create a practical and stylish cooking space. From the sleek L-shaped layout to the efficient storage solutions, our design enhances your culinary journey. ",
        para2:
          "Cook with ease and elegance in this thoughtfully designed kitchen.",
      },
      {
        id: 3,
        title: "U Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Ushape1.png"),
        para1:
          "Experience seamless workflow in our U-shaped modular kitchens. We prioritize ergonomics and convenience, ensuring you have everything at your fingertips.",
        para2: " Our designs turn cooking into a joyful experience.",
      },

      {
        id: 5,
        title: "C Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Cshape1.png"),
        para1:
          "Elevate your kitchen with our C-shaped modular designs. We provide ample workspace and storage, allowing you to organize with ease.",
        para2:
          " Our kitchens reflect your taste and cater to your culinary needs.",
      },
      {
        id: 6,
        title: "Island Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Island1.png"),
        para1:
          "Elevate your kitchen with our island designs. We create central hubs for cooking and socializing, where functionality meets elegance. ",
        para2: "Our kitchens are a testament to the heart of your home.",
      },
      {
        id: 7,
        title: "Island Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Island2.png"),
        para1:
          "Experience the heart of your home with our second island kitchen design. We've optimized the layout for seamless workflow and aesthetic appeal. The island's versatility makes meal preparation a joy, while the elegant design creates a captivating focal point. ",
        para2: "Cook and connect in this beautifully designed kitchen.",
      },
      // {
      //   id: 8,
      //   title: "Straight Shape",
      //   subHeading: "Residential",
      //   pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Straight1.png"),
      //   para1:
      //     "Discover the beauty of simplicity in our straight-shaped kitchen design. We've prioritized clean lines and practicality to create a kitchen that caters to your needs. ",
      //   para2:
      //     "From efficient storage to modern aesthetics, this design offers a minimalist approach to cooking. Enjoy the ease and elegance of this well-thought-out kitchen.",
      // },
      {
        id: 9,
        title: "Parallel Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Straight2.png"),
        para1:
          "Simplify your kitchen routine with our straight-shaped modular kitchens. We maintain a sleek, uncluttered look while optimizing functionality. ",
        para2: "Cooking becomes a pleasure in these streamlined spaces.",
      },
      {
        id: 10,
        title: "French Theme",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-French.png"),
        para1:
          "Transport yourself to the French countryside with our French-themed kitchens. We blend rustic charm with modern convenience, creating kitchens that are both cozy and practical. ",
        para2: "Enjoy the warmth of a French kitchen in your own home.",
      },
    ],
  },

  {
    id: 5,
    img: require("../Images/Study Room.png"),
    heading: "Study Room",
    pophead: "Residential < Study Room",
    popImg: [
      {
        id: 1,
        title: "Library",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-library1.png"),
        para1:
          "Dive into a world of inspiration in our library-themed study room. We craft serene spaces that encourage focus and creativity. ",
        para2:
          "From cozy reading nooks to extensive bookshelves, we cater to your intellectual pursuits, making studying a delightful journey.",
      },
      {
        id: 2,

        title: "Library",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-library2.png"),
        para1:
          "Our library-themed study room continues to enchant with its second image. This space marries functionality with aesthetics, offering a tranquil environment for focused learning and relaxation.",
        para2:
          " Whether it's a quiet nook for solitude or a shared space for family reading time, our designs foster a love for literature.",
      },
      {
        id: 3,

        title: "Seating",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-Seating1.png"),
        para1:
          "Immerse yourself in comfort and inspiration with our study room seating. We've created cozy nooks that invite you to delve into your studies with ease. Our plush seating options offer relaxation and support, making your study breaks enjoyable. ",
        para2: "Elevate your learning experience in this comfortable haven",
      },
      {
        id: 4,

        title: "Seating",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-Seating2.png"),
        para1:
          " Embrace the coziness of our second seating design in the study room. We've curated inviting corners where you can dive into your books or engage in discussions. The soft furnishings and thoughtful layout provide the perfect backdrop for intellectual exploration. ",
        para2:
          "Create meaningful connections and absorb knowledge in this welcoming atmosphere. ",
      },
      {
        id: 5,
        title: "Creative Space",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-Creativespace1.png"),
        para1:
          "Unleash your creativity in the first image of our creative space-inspired study room. We craft environments that stimulate innovation and artistic expression. ",
        para2:
          "From flexible workstations to inspirational decor, our designs inspire you to explore your creative potential.",
      },
      {
        id: 6,
        title: "Creative Space",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-Creativespace2.png"),
        para1:
          "Dive into the world of limitless possibilities in our second creative space. We've curated an environment that nurtures your artistic endeavors. With ample room to brainstorm, sketch, or craft, this design encourages you to push the boundaries of your creativity. ",
        para2:
          "Transform your ideas into masterpieces in this inviting sanctuary.",
      },
      {
        id: 7,

        title: "Study table",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-Studytable1.png"),
        para1:
          "Enhance your productivity with our study table design. We've engineered a workspace that combines functionality with style. From ergonomic design to clever storage solutions, our study tables are tailored to your needs. ",
        para2:
          "Get ready to conquer your tasks in a space that's both practical and aesthetically pleasing.",
      },
      {
        id: 8,

        title: "Study table",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Studyroom-Studytable2.png"),
        para1:
          "Elevate your focus and productivity with our study table design. We've created a dedicated workspace that blends functionality with style. Our ergonomic design ensures comfort during long study sessions, while the elegant aesthetics inspire your academic journey. ",
        para2:
          "Study smarter and more comfortably in this inviting atmosphere.",
      },
    ],
  },

  {
    id: 6,
    img: require("../Images/Worship Place.png"),
    heading: "Worship Place",
    pophead: "Residential < Worship Place",
    popImg: [
      // {
      //   id: 1,
      //   title: "Temple",
      //   subHeading: "Residential",
      //   pop: require("../Images/library1.png"),
      //   para1:
      //     "Dive into a world of inspiration in our library-themed study room. We craft serene spaces that encourage focus and creativity. ",
      //   para2:
      //     "From cozy reading nooks to extensive bookshelves, we cater to your intellectual pursuits, making studying a delightful journey.",
      // },
      // {
      //   id: 2,
      //   title: "Temple",
      //   subHeading: "Residential",

      //   pop: require("../Images/library2.png"),
      //   para1:
      //     "Our library-themed study room continues to enchant with its second image. This space marries functionality with aesthetics, offering a tranquil environment for focused learning and relaxation. ",
      //   para2:
      //     "Whether it's a quiet nook for solitude or a shared space for family reading time, our designs foster a love for literature.",
      // },
      {
        id: 3,
        title: "Meditation room",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Worshiproom-Meditation1.png"),
        para1:
          "Find inner calm and mindfulness in our meditation room design. We've created a tranquil retreat that fosters inner peace and self-discovery. ",
        para2:
          "The minimalist decor and soothing colors provide the ideal environment for your meditation practice. Let go of the noise and distractions and center yourself in this serene space.",
      },
      {
        id: 4,
        title: "Meditation room",
        subHeading: "Residential",

        pop: require("../Compress Img/Interior-residential/Int-re-Worshiproom-Meditation2.png"),
        para1:
          " Elevate your meditation experience with our second meditation room design. We've incorporated elements of nature and serenity to create a harmonious atmosphere. ",
        para2:
          "From natural textures to calming sounds, this design supports your journey towards mindfulness and spiritual growth.",
      },
    ],
  },

  {
    id: 7,
    img: require("../Images/Balcony.png"),
    heading: "Balcony",
    pophead: "Residential < Balcony",
    popImg: [
      {
        id: 1,
        title: "Seating",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Balcony-Seating1.png"),
        para1:
          "Embrace the outdoors from the comfort of our balcony seating design. We've curated cozy corners that invite you to relax and unwind amidst nature's beauty. The comfortable seating arrangements and stylish accessories create an inviting atmosphere where you can savor the fresh air and scenic views.  ",
        para2: "Make memories and cherish moments in this tranquil space.",
      },

      {
        id: 2,
        title: "Seating",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Balcony-Seating2.png"),
        para1:
          "Discover the art of relaxation with our second balcony seating design. We've focused on comfort and aesthetics to offer you an oasis in the heart of your home. Sink into plush cushions, enjoy a good book, or simply soak in the serenity. ",
        para2:
          "This design transforms your balcony into a retreat where you can escape the hustle and bustle.",
      },

      {
        id: 3,
        title: "Plants",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Balcony-Plants1.png"),
        para1:
          "Immerse yourself in the lush greenery of our balcony plants design. We've brought nature closer to you with a variety of potted plants and greenery. Enjoy the therapeutic benefits of gardening in your own urban oasis. ",
        para2:
          "This design transforms your balcony into a refreshing green escape.",
      },

      {
        id: 4,
        title: "Plants",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Balcony-Plants2.png"),
        para1:
          "Elevate your connection to nature with our second balcony plants design. We've curated a diverse collection of plants to create a soothing environment. ",
        para2:
          "Breathe in the freshness and let your balcony become a vibrant and harmonious sanctuary. Experience the serenity of nature right outside your door.",
      },
    ],
  },

  {
    id: 8,
    img: require("../Images/Bathroom.png"),
    heading: "Bathroom",
    pophead: "Residential < Bathroom",
    popImg: [
      {
        id: 1,
        title: "Shower System",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bathroom-Shower1.png"),
        para1:
          "Transform your daily routine with our innovative shower system designs. We prioritize functionality and luxury, offering a spa-like experience in the comfort of your home. ",
        para2:
          "Refresh and rejuvenate with our state-of-the-art shower systems.",
      },
      {
        id: 2,
        title: "Shower System",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bathroom-Shower2.png"),
        para1:
          "Step into a world of luxury with our  shower system design. We've reimagined the shower experience to provide you with a therapeutic escape. ",
        para2:
          "From adjustable water pressure to mood-enhancing lighting, this design elevates your daily rituals into moments of pure indulgence.",
      },

      {
        id: 3,
        title: "Tile Type",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bathroom-Tyle1.png"),
        para1:
          "Elevate your bathroom aesthetics with our diverse tile type designs. We offer a wide range of tile options, from classic to contemporary, allowing you to customize your bathroom's look and feel. ",
        para2: "Our tiles add personality and style to your space.",
      },
      {
        id: 4,
        title: "Tile Type",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Bathroom-Tyle2.png"),
        para1:
          "Immerse yourself in the beauty of our second tile type design. We've curated a selection of tiles that combine functionality and elegance seamlessly.           ",
        para2:
          "From timeless patterns to modern finishes, our design transforms your bathroom into a work of art, where every detail matters.",
      },
    ],
  },
];

export const IntCommItems = [
  {
    id: 1,
    img: require("../Images/Intshop.png"),
    heading: "Shops",
    pophead: "Commercial < Shops",
    popImg: [
      {
        id: 1,
        title: "Medical",
        pop: require("../Compress Img/Interior - commercial/Int-cm-shop-medical.png"),
        subHeading: "Commercial",

        para1:
          "In our medical shop design, we prioritize functionality and accessibility. We create spaces that allow for efficient inventory management while ensuring a welcoming atmosphere for customers. ",
        para2:
          "Your medical store will be both organized and inviting, making it easy for clients to find what they need.",
      },
      {
        id: 2,
        title: "Clothing",
        pop: require("../Compress Img/Interior - commercial/Int-cm-shop-clothing1.png"),
        subHeading: "Commercial",
        para1:
          "Take a versatile approach to your clothing shop with our second design choice. We value adaptability, offering flexible layouts and modern fixtures. ",
        para2:
          "This option caters to the ever-changing needs of your clothing store, allowing you to easily adjust the space for different fashion seasons and collections.",
      },
      {
        id: 3,

        title: "Clothing",
        pop: require("../Compress Img/Interior - commercial/Int-cm-shop-clothing2.png"),
        subHeading: "Commercial",
        para1:
          "Discover elegance in our clothing shop design. We blend aesthetics and practicality, creating spaces that showcase your fashion collections beautifully. ",
        para2:
          "From modern display solutions to fitting rooms designed for comfort, we tailor your store to provide a seamless shopping experience.",
      },
      {
        id: 4,
        title: "Grocery",
        pop: require("../Compress Img/Interior - commercial/Int-cm-shop-grocery1.png"),
        subHeading: "Commercial",
        para1:
          "Optimize your grocery store with our first design pick. We focus on creating a practical layout that maximizes space efficiency.  ",
        para2:
          "From well-organized aisles to optimized checkout counters, this option ensures a hassle-free and convenient shopping experience for your customers.",
      },

      {
        id: 5,
        title: "Grocery",
        pop: require("../Compress Img/Interior - commercial/Int-cm-shop-grocery2.png"),
        subHeading: "Commercial",
        para1:
          "Streamline your grocery store operations with our second design choice. We prioritize functionality and accessibility, offering clear signage and efficient shelving. ",
        para2:
          "This option caters to the need for quick and convenient access to products, making grocery shopping a breeze. ",
      },
      {
        id: 7,
        title: "Storage",
        pop: require("../Compress Img/Interior - commercial/Int-cm-shop-storage1.png"),
        subHeading: "Commercial",
        para1:
          "Transform your storage areas with our first design selection. We focus on maximizing space utilization while maintaining an organized appearance. ",
        para2:
          "From efficient shelving systems to intelligent inventory management, this option ensures that your storage facilities support smooth operations for your shop.",
      },
      {
        id: 8,
        title: "Storage",
        pop: require("../Compress Img/Interior - commercial/Int-cm-shop-storage2.png"),
        subHeading: "Commercial",
        para1:
          "Optimize your shop's storage with our second design pick. We emphasize practicality and accessibility, providing modular storage solutions and clear labeling. ",
        para2:
          "This option caters to the need for quick and efficient access to merchandise and inventory, streamlining your shop's daily tasks.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/Intindustry.png"),
    heading: "Industrial",
    pophead: "Commercial < Industrial",
    popImg: [
      {
        id: 1,
        title: "Office’s",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-office1.png"),
        para1:
          "Create a productive work environment with our dedicated workstation design. We focus on individual workspace efficiency, providing ergonomic furniture and ample storage. ",
        para2:
          " Your employees will have everything they need for focused work, allowing them to excel in their roles.",
      },
      {
        id: 2,
        title: "Office’s",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-office2.png"),
        para1:
          "Enhance your industrial office spaces with our first design choice. We craft environments that seamlessly blend practicality and style. ",
        para2:
          "Featuring ergonomic workstations, inviting common areas, and modern meeting rooms, this option ensures your office promotes both productivity and creativity.",
      },
      {
        id: 3,
        title: "Office’s",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-office3.png"),
        para1:
          "Adopt a flexible approach to your industrial offices with our second design option. We value adaptability, offering modular furniture and versatile spaces. ",
        para2:
          "This option caters to the ever-changing needs of your industrial setting, allowing you to transform your office space to meet various requirements, from team collaboration to private work.",
      },
      {
        id: 4,
        title: "Admin building",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-adminbuilding1.png"),
        para1:
          "Elevate your admin building with our thoughtful designs. We understand the need for efficiency and professionalism in administrative spaces. ",
        para2:
          "Our designs offer organized workstations, functional storage, and inviting common areas, making your admin building a well-rounded center of operations.",
      },
      {
        id: 5,
        title: "Admin building",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-adminbuilding2.png"),
        para1:
          "Optimize your admin building with our first design choice. We provide efficient, space-saving solutions for administrative areas. ",
        para2:
          "From well-organized workstations to streamlined storage, this option ensures that your admin building becomes a hub of efficiency and organization, supporting the smooth operation of your industrial facility.",
      },
      {
        id: 6,
        title: "Storage",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-storage1.png"),
        para1:
          "Transform your storage areas with our first design choice. We maximize space utilization while maintaining a clean and organized look.",
        para2:
          " From high-density shelving to intelligent inventory management solutions, this option ensures that your storage facilities are efficient and easy to navigate.",
      },
      {
        id: 7,
        title: "Storage",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-storage2.png"),
        para1:
          "Optimize your industrial storage with our second design choice. We emphasize practicality and accessibility, offering modular storage systems and clear labeling. ",
        para2:
          "This option caters to the need for quick and efficient access to materials and inventory, streamlining your industrial operations.",
      },
    ],
  },

  {
    id: 3,
    img: require("../Images/Inthospital.png"),
    heading: "Hospitals",
    pophead: "Commercial < Hospital",

    popImg: [
      {
        id: 1,
        title: "Operation Theatre",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-operationtheatre1.png"),
        para1:
          " Our operation theatre designs prioritize the highest standards of hygiene and functionality. We create spaces where surgical teams can work with precision, ensuring the best possible patient outcomes. ",
        para2: "Safety and cleanliness are at the forefront of our designs.",
      },
      {
        id: 2,
        title: "Operation Theatre",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-operationtheatre2.png"),

        para1:
          " Experience efficiency in our second operation theatre design option. We focus on workflow optimization and easy accessibility to equipment.",
        para2:
          " This option is designed to enhance surgical teams' coordination and effectiveness while maintaining the highest standards of cleanliness and safety.",
      },
      {
        id: 3,
        title: "I.C.U",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-ICU1.png"),
        para1:
          "Create a calming atmosphere in our first intensive care unit design option. We emphasize patient comfort with soothing colors, adjustable lighting, and ergonomic furnishings.. ",
        para2:
          " This option provides a serene environment where patients can receive the critical care they need while feeling supported and at ease",
      },
      {
        id: 4,
        title: "I.C.U",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-ICU2.png"),
        para1:
          "Prioritize functionality in our second intensive care unit design option. We offer a well-organized layout with advanced monitoring systems and efficient nurse stations. .",
        para2:
          " This option ensures medical staff have everything at their fingertips, allowing them to deliver the highest level of care to patients in critical condition",
      },
      {
        id: 5,
        title: "Waiting area",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-waitingarea1.png"),
        para1:
          "Optimize space and efficiency with our second waiting area design option. We provide modular seating arrangements and clear signage for easy navigation. ",
        para2:
          "This option ensures that waiting areas remain organized and accessible, enhancing the overall patient experience.",
      },
      {
        id: 6,
        title: "Waiting area",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-waitingarea2.png"),
        para1:
          "Welcome patients and their families with our first waiting area design option. We create a warm and inviting space with comfortable seating, soft lighting, and engaging distractions. ",
        para2:
          "This option ensures that waiting becomes a more pleasant experience, reducing anxiety and promoting a positive atmosphere.",
      },
      {
        id: 7,
        title: "Reception",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-reception1.png"),
        para1:
          "Embrace a modern approach to reception with our second design option. We focus on efficiency and digital check-in processes.",
        para2:
          " This option streamlines patient registration and enhances administrative workflows, reducing waiting times and improving the overall efficiency of your hospital's operations. ",
      },
      // {
      //   id: 8,
      //   title: "Reception",
      //   subHeading: "Commercial",

      //   pop: require("../Compress Img/Interior - commercial/Int-cm-hospital-reception2.png"),
      //   para1:
      //     "Make a lasting first impression with our first reception design option. We prioritize a welcoming atmosphere with friendly staff and a well-organized front desk. ",
      //   para2:
      //     " This option ensures that patients and visitors feel valued and attended to from the moment they enter the hospital. ",
      // },
    ],
  },

  {
    id: 4,
    img: require("../Images/Intoffice.png"),
    heading: " Offices",
    pophead: "Commercial < Offices",
    popImg: [
      {
        id: 2,
        title: "Work Stations",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-office-workstation3.png"),
        para1:
          " Boost your team's productivity with our open-concept workstation design. We prioritize collaborative workspaces that encourage communication and idea sharing.",
        para2:
          "  From adjustable desks to comfortable seating, our design fosters a dynamic and vibrant office culture.",
      },
      {
        id: 3,
        title: "Work Stations",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-office-workstation2.png"),
        para1:
          "Boost your team's productivity with our open-concept workstation design. We prioritize collaborative workspaces that encourage communication and idea sharing. ",
        para2:
          " From adjustable desks to comfortable seating, our design fosters a dynamic and vibrant office culture.",
      },
      {
        id: 4,
        title: "Cabin",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-office-cabin1.png"),

        para1:
          "Opt for a contemporary twist on your executive cabin with our modern design. We prioritize clean lines and minimalistic aesthetics while maintaining the comfort and functionality required for decision-makers. ",
        para2: "Your cabin will reflect your progressive leadership style.",
      },
      {
        id: 5,
        title: "Cabin",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-office-cabin2.png"),

        para1:
          "Elevate your executive cabin with our classic design. We blend timeless elegance with modern functionality, creating a space that exudes professionalism.  ",
        para2:
          " From rich wood finishes to plush seating, your cabin will be a statement of authority and style.",
      },

      {
        id: 6,
        title: "Meeting room",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-office-meetingroom1.png"),

        para1:
          "Make a statement with our first meeting room design option. We create spaces that foster creativity and collaboration, featuring stylish furnishings, cutting-edge technology, and ample natural light. ",
        para2:
          "Whether it's brainstorming sessions or client presentations, this option ensures your meetings are memorable and productive.",
      },
      {
        id: 7,
        title: "Meeting room",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-office-meetingroom2.png"),
        para1:
          "Explore versatility with our second meeting room design option. We prioritize flexibility, offering modular furniture and adaptable layouts. ",
        para2:
          "This option caters to the ever-changing needs of modern workplaces, allowing you to transform your meeting space for various purposes, from formal presentations to casual team huddles.",
      },
      {
        id: 8,
        title: "Storage",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-office-storage1.png"),

        para1:
          "Optimize your office storage with our first design option. We maximize space efficiency while maintaining a sleek, contemporary look. ",
        para2:
          "From built-in cabinets to intelligent shelving systems, this option ensures that everything has its place, helping you stay organized and clutter-free.",
      },
      {
        id: 9,
        title: "Storage",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-office-storage2.png"),

        para1:
          "Embrace practicality with our second storage design option. We provide robust storage solutions that prioritize accessibility and ease of use. ",
        para2:
          "This option features open shelving, mobile storage units, and smart organization tools, allowing your team to access what they need when they need it, promoting efficiency in your workspace.",
      },
      {
        id: 10,
        title: "Lounge Area",
        subHeading: "Commercial",

        pop: require("../Compress Img/Interior - commercial/Int-cm-office-commonarea1.png"),

        para1:
          "Elevate your common areas with our first design option. We create inviting spaces where employees can relax and recharge. This option features comfortable seating, vibrant artwork, and greenery to enhance the atmosphere. ",
        para2:
          "Whether it's a coffee break or a quick chat, these common areas foster a sense of community and well-being.",
      },
      {
        id: 11,
        subHeading: "Commercial",

        title: " Relaxation Area",
        pop: require("../Compress Img/Interior - commercial/Int-cm-office-commonarea2.png"),

        para1:
          "Embrace a modern approach to common areas with our second design option. We focus on multifunctionality, offering flexible seating arrangements and integrated technology. ",
        para2:
          "This option caters to the evolving needs of today's workplaces, providing spaces for collaboration, informal meetings, and individual relaxation, all in one dynamic environment.",
      },
    ],
  },
];

export const TrunkeyItems = [
  {
    id: 1,
    img: require("../Images/civilconstruction.png"),
    heading: "Civil Construction",
    pophead: "Turnkey < Civil Construction",
    popImg: [
      {
        id: 1,
        title: "Civil Construction",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Civilconstruction1.png"),
        para1:
          "Enhance your project with our first civil construction choice. We build structures with an unwavering commitment to strength and endurance. ",
        para2:
          "From laying solid foundations to erecting sturdy frameworks, this choice ensures your construction project stands tall and steadfast, ready to face the test of time.",
      },
      {
        id: 2,
        title: "Civil Construction",
        pop: require("../Compress Img/Turnkey/Turnkey-Civilconstruction2.png"),

        para1:
          "We don't just build; we innovate. Our focus is on pioneering materials and practices that make structures not just robust but also environmentally conscious. ",
        para2:
          "This choice caters to projects that demand both strength and eco-friendliness.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/intexecution.png"),
    heading: "Interior Execution",
    pophead: "Turnkey < Interior Execution",
    popImg: [
      {
        id: 1,
        title: "Interior execution",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-InteriorExecution1.png"),
        para1:
          "Immerse yourself in a realm where form and function blend seamlessly with our first interior execution option. We craft spaces that are not just pleasing to the eye but also exceptionally practical.",
        para2:
          "From chic furnishings to clever storage solutions, this choice ensures your interiors mirror your unique style while serving their purpose flawlessly.",
      },
    ],
  },

  {
    id: 3,
    img: require("../Images/waterproffing.png"),
    heading: "Water Proofing",
    pophead: "Turnkey < Water Proofing",
    popImg: [
      {
        id: 1,
        title: "Water proofing",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Waterproofing.png"),
        para1:
          "Streamline your waterproofing experience with our second design. Efficiency is the name of the game. We provide efficient systems and regular maintenance plans to keep things watertight. ",
        para2:
          "This choice caters to those who seek a hassle-free solution to water-related worries.",
      },
    ],
  },

  {
    id: 4,
    img: require("../Images/wallpainting.png"),
    heading: "Wall Painting",
    pophead: "Turnkey < Wall Painting",
    popImg: [
      {
        id: 1,
        title: "Painting",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Painting1.png"),
        para1:
          "Unleash practicality and aesthetics in your space with our second design. We offer an array of paint options and expert color consultations. ",
        para2:
          "This choice is for those who desire visually pleasing and customized paint solutions that add an artistic touch to their environment.",
      },
    ],
  },

  {
    id: 5,
    img: require("../Images/plumbing.png"),
    heading: "Plumbing",
    pophead: "Turnkey < Plumbing",
    popImg: [
      {
        id: 1,
        title: "Plumbing",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Plumbing1.png"),

        para1:
          "Immerse yourself in a world where plumbing runs smoothly with our first design. We specialize in crafting robust plumbing systems that deliver consistent water flow and prevent leaks. ",
        para2:
          "This choice ensures your plumbing project operates seamlessly and securely, leaving no room for dripping worries.",
      },
    ],
  },

  {
    id: 6,
    img: require("../Images/electrial.png"),
    heading: "Electrical",
    pophead: "Turnkey < Electrical",
    popImg: [
      {
        id: 1,
        title: "Electrical",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Electrical1.png"),

        para1:
          "Energize your surroundings with our first electrical design. We specialize in creating reliable and safe electrical setups. ",
        para2:
          "From quality wiring to advanced automation, this option ensures your electrical project operates seamlessly and securely, lighting up your world without a glitch.",
      },
      {
        id: 2,
        title: "Electrical",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Electrical2.png"),

        para1:
          "Explore versatility in electrical systems with our second option. We value adaptability, offering smart home solutions and energy-efficient designs. ",
        para2:
          "This choice caters to those who demand innovation and sustainability in their electrical project, bringing modernity and efficiency to every switch.",
      },
    ],
  },

  {
    id: 7,
    img: require("../Images/flooring.png"),
    heading: "Flooring",
    pophead: "Turnkey < Flooring",
    popImg: [
      {
        id: 1,
        title: "Flooring",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Flooring1.png"),

        para1:
          "Immerse your spaces in the art of flooring with our first choice. We specialize in providing exquisite flooring solutions that combine aesthetics and durability. ",
        para2:
          "From hardwood elegance to practical tile options, this option ensures your flooring enhances the overall appeal and functionality of your project.",
      },
    ],
  },

  {
    id: 8,
    img: require("../Images/fabrication.png"),
    heading: "Fabrication",
    pophead: "Turnkey < Fabrication",
    popImg: [
      {
        id: 1,
        title: "Fabrication",
        subHeading: "Turnkey",
        pop: require("../Compress Img/Turnkey/Turnkey-Fabrication1.png"),
        para1:
          "Streamline your fabrication with our second design. We emphasize efficiency and innovation, offering advanced fabrication techniques and material optimization. ",
        para2:
          "This option is for those who seek cost-effective and cutting-edge fabrication solutions, where every piece is a testament to both craftsmanship and innovation.",
      },
    ],
  },
];

export const HomeInterior = [
  {
    id: 1,
    img: require("../Images/interior1.png"),
    heading: "Kitchen",
    pophead: "Interior < Kitchen",
    popImg: [
      {
        id: 1,
        title: "L Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Lshape1.png"),
        para1:
          "Maximize space and efficiency with our L-shaped modular kitchen designs. We optimize every corner, making meal preparation a breeze. ",
        para2:
          "Our kitchens are a blend of functionality and aesthetics, transforming your cooking space into a culinary haven.",
      },
      {
        id: 2,
        title: "L Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Lshape2.png"),
        para1:
          " Experience culinary excellence with our L-shaped modular kitchen design. We've optimized every inch to create a practical and stylish cooking space. From the sleek L-shaped layout to the efficient storage solutions, our design enhances your culinary journey. ",
        para2:
          "Cook with ease and elegance in this thoughtfully designed kitchen.",
      },
      {
        id: 3,
        title: "U Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Ushape1.png"),
        para1:
          "Experience seamless workflow in our U-shaped modular kitchens. We prioritize ergonomics and convenience, ensuring you have everything at your fingertips.",
        para2: " Our designs turn cooking into a joyful experience.",
      },

      {
        id: 5,
        title: "C Shape Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Cshape1.png"),
        para1:
          "Elevate your kitchen with our C-shaped modular designs. We provide ample workspace and storage, allowing you to organize with ease.",
        para2:
          " Our kitchens reflect your taste and cater to your culinary needs.",
      },
      {
        id: 6,
        title: "Island Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Island1.png"),
        para1:
          "Elevate your kitchen with our island designs. We create central hubs for cooking and socializing, where functionality meets elegance. ",
        para2: "Our kitchens are a testament to the heart of your home.",
      },
      {
        id: 7,
        title: "Island Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Island2.png"),
        para1:
          "Experience the heart of your home with our second island kitchen design. We've optimized the layout for seamless workflow and aesthetic appeal. The island's versatility makes meal preparation a joy, while the elegant design creates a captivating focal point. ",
        para2: "Cook and connect in this beautifully designed kitchen.",
      },
      // {
      //   id: 8,
      //   title: "Straight Shape",
      //   subHeading: "Residential",
      //   pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Straight1.png"),
      //   para1:
      //     "Discover the beauty of simplicity in our straight-shaped kitchen design. We've prioritized clean lines and practicality to create a kitchen that caters to your needs. ",
      //   para2:
      //     "From efficient storage to modern aesthetics, this design offers a minimalist approach to cooking. Enjoy the ease and elegance of this well-thought-out kitchen.",
      // },
      {
        id: 9,
        title: "Parallel Kitchen",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-Straight2.png"),
        para1:
          "Simplify your kitchen routine with our straight-shaped modular kitchens. We maintain a sleek, uncluttered look while optimizing functionality. ",
        para2: "Cooking becomes a pleasure in these streamlined spaces.",
      },
      {
        id: 10,
        title: "French Theme",
        subHeading: "Residential",
        pop: require("../Compress Img/Interior-residential/Int-re-Kitchen-French.png"),
        para1:
          "Transport yourself to the French countryside with our French-themed kitchens. We blend rustic charm with modern convenience, creating kitchens that are both cozy and practical. ",
        para2: "Enjoy the warmth of a French kitchen in your own home.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/interior2.png"),
    heading: "Industrial",
    pophead: "Interior < Industrial",
    popImg: [
      {
        id: 1,
        title: "Office’s",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-office1.png"),
        para1:
          "Create a productive work environment with our dedicated workstation design. We focus on individual workspace efficiency, providing ergonomic furniture and ample storage. ",
        para2:
          " Your employees will have everything they need for focused work, allowing them to excel in their roles.",
      },
      {
        id: 2,
        title: "Office’s",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-office2.png"),
        para1:
          "Enhance your industrial office spaces with our first design choice. We craft environments that seamlessly blend practicality and style. ",
        para2:
          "Featuring ergonomic workstations, inviting common areas, and modern meeting rooms, this option ensures your office promotes both productivity and creativity.",
      },
      {
        id: 3,
        title: "Office’s",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-office3.png"),
        para1:
          "Adopt a flexible approach to your industrial offices with our second design option. We value adaptability, offering modular furniture and versatile spaces. ",
        para2:
          "This option caters to the ever-changing needs of your industrial setting, allowing you to transform your office space to meet various requirements, from team collaboration to private work.",
      },
      {
        id: 4,
        title: "Admin building",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-adminbuilding1.png"),
        para1:
          "Elevate your admin building with our thoughtful designs. We understand the need for efficiency and professionalism in administrative spaces. ",
        para2:
          "Our designs offer organized workstations, functional storage, and inviting common areas, making your admin building a well-rounded center of operations.",
      },
      {
        id: 5,
        title: "Admin building",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-adminbuilding2.png"),
        para1:
          "Optimize your admin building with our first design choice. We provide efficient, space-saving solutions for administrative areas. ",
        para2:
          "From well-organized workstations to streamlined storage, this option ensures that your admin building becomes a hub of efficiency and organization, supporting the smooth operation of your industrial facility.",
      },
      {
        id: 6,
        title: "Storage",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-storage1.png"),
        para1:
          "Transform your storage areas with our first design choice. We maximize space utilization while maintaining a clean and organized look.",
        para2:
          " From high-density shelving to intelligent inventory management solutions, this option ensures that your storage facilities are efficient and easy to navigate.",
      },
      {
        id: 7,
        title: "Storage",
        subHeading: "Commercial",
        pop: require("../Compress Img/Interior - commercial/Int-cm-industrial-storage2.png"),
        para1:
          "Optimize your industrial storage with our second design choice. We emphasize practicality and accessibility, offering modular storage systems and clear labeling. ",
        para2:
          "This option caters to the need for quick and efficient access to materials and inventory, streamlining your industrial operations.",
      },
    ],
  },
];

export const HomeArchitect = [
  {
    id: 1,
    img: require("../Images/bunglows.png"),
    heading: "Bungalows",
    pophead: "Architecture < Bungalows",
    popImg: [
      {
        id: 1,
        title: "Bungalows",
        subHeading: "Residential",

        pop: require("../Compress Img/Architecture-residential/Arc-Res-Bungalow1.png"),

        para1:
          "Dive into the world of luxury living with our first bungalow design. We craft spacious, elegant, and functional layouts that redefine modern living. ",
        para2:
          "From grand facades to meticulously designed interiors, this option ensures your bungalow is a sanctuary of style and comfort.",
      },
      {
        id: 2,
        title: "Bungalows",
        subHeading: "Residential",

        pop: require("../Compress Img/Architecture-residential/Arc-Res-Bungalow2.png"),

        para1:
          "Embrace contemporary living with our bungalow design. We focus on open spaces, natural light, and sustainability. ",
        para2:
          "This option offers versatile layouts and eco-friendly features, catering to those who seek a balance between modernity and environmental consciousness.",
      },
      {
        id: 3,

        title: "Bungalows",
        subHeading: "Residential",

        pop: require("../Compress Img/Architecture-residential/Arc-Res-Bungalow3.png"),

        para1:
          "Discover timeless charm in our bungalow design. We create classic yet practical spaces that evoke a sense of nostalgia. ",
        para2:
          "From charming verandas to cozy interiors, this option ensures your bungalow exudes warmth and traditional appeal.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/Shop.png"),
    heading: "Shops",
    pophead: "Architecture < Shops",
    popImg: [
      {
        id: 1,
        title: "Shops",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Shop1.png"),
        para1:
          "Transform retail with our first shop design. We specialize in crafting inviting, customer-centric spaces.  ",
        para2:
          "From eye-catching storefronts to intuitive layouts, this option ensures your shop is a magnet for foot traffic, enhancing the retail experience.",
      },
      {
        id: 2,
        title: "Shops",
        subHeading: "Commercial",

        pop: require("../Compress Img/Architecture-commercial/Arc-Com-Shop2.png"),
        para1:
          "Unleash modernity in retail with our shop design. We focus on innovative layouts and digital integration. ",
        para2:
          "This option caters to those who seek cutting-edge retail solutions that combine physical and online shopping seamlessly.",
      },
    ],
  },
];

export const HomeTrunkey = [
  {
    id: 1,
    img: require("../Images/interior5.png"),
    heading: "Flooring",
    pophead: "Turnkey < Flooring",
    popImg: [
      {
        id: 1,
        title: "Flooring",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-Flooring1.png"),

        para1:
          "Immerse your spaces in the art of flooring with our first choice. We specialize in providing exquisite flooring solutions that combine aesthetics and durability. ",
        para2:
          "From hardwood elegance to practical tile options, this option ensures your flooring enhances the overall appeal and functionality of your project.",
      },
    ],
  },

  {
    id: 2,
    img: require("../Images/interior6.png"),
    heading: "Interior Execution",
    pophead: "Turnkey < Interior Execution",
    popImg: [
      {
        id: 1,
        title: "Interior execution",
        subHeading: "Turnkey",

        pop: require("../Compress Img/Turnkey/Turnkey-InteriorExecution1.png"),
        para1:
          "Immerse yourself in a realm where form and function blend seamlessly with our first interior execution option. We craft spaces that are not just pleasing to the eye but also exceptionally practical.",
        para2:
          "From chic furnishings to clever storage solutions, this choice ensures your interiors mirror your unique style while serving their purpose flawlessly.",
      },
    ],
  },
];
