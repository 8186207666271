import React from "react";
import "./HomePage.css";
import EastIcon from "@mui/icons-material/East";
import HomeFooter from "../Component/HomeFooter";
import HomeInteriorProjects from "../Component/HomeInteriorProjects";
import HomeArchitectProjects from "../Component/HomeArchitectProjects";
import HomeTrunkeyProjects from "../Component/HomeTrunkeyProjects";

const HomePage = () => {
  return (
    <div className="homepage_main">
      <div className="position-relative">
        <div className=" col-md-7 col-sm-12 img-container">
          <img
            src={require("../Images/heroimg.png")}
            className="img-fluid w-100"
            alt="img"
          />
        </div>

        <div className="homepage-overtext col-md-6">
          <h3>
            Elevate Your <br />
            Lifestyle With Us
          </h3>
          <p>
            Our Designs speak to your unique taste
            <br /> and bring joy to your daily living
          </p>
        </div>
      </div>

      <section className="container col-md-10 col-sm-12 col-md-mt-5 pt-4 pb-5 text-para ">
        <hr className="special-hr" />
        <h1 className="d-inline-block mb-0 homehead">
          {" "}
          Why M’s Design Studio?
        </h1>
        <p className="ps-md-5 pt-3 ">
          Welcome to M’s Design Studio, where themes, tenacity, and timelessness
          define our craft. We believe in the art of living, not just
          decorating. Our theme-based designs transcend trends, creating spaces
          that are uniquely you.
        </p>
        <p className="ps-md-5">
          Beyond installation, we ensure your vision thrives. And with a 10-year
          warranty, your satisfaction stands the test of time. Welcome to a
          studio that crafts not just interiors, but legacies.
        </p>
        <div className="  row col-md-12 col-md-mt-5 mx-5 mt-5">
          <div className="homepage_shows col-sm-12  col-md-4 mb-3">
            <img
              src={require("../Images/theme.png")}
              className="img-fluid"
              alt="premium"
            />
            <h3 className="mt-3"> Theme Based Designs</h3>
          </div>
          <div className="homepage_shows col-sm-12  col-md-4 mb-3">
            <img
              src={require("../Images/service.png")}
              className="img-fluid "
              alt="premium"
            />
            <h3 className="mt-3"> Post Installation Service</h3>
          </div>
          <div className="homepage_shows col-sm-12  col-md-4 mb-3">
            <img
              src={require("../Images/warranty.png")}
              className="img-fluid "
              alt="premium"
            />
            <h3 className="mt-3"> 10 Years Warranty</h3>
          </div>
        </div>
      </section>

      <section>
        <div className="maintxt col-md-12 pt-md-5 pb-5 ">
          <div className=" container d-md-flex flex-wrap align-items-center col-md-12 col-sm-12 ">
            <div className="  col-md-5 ">
              <h2 className="d-md-none text-head px-3 mb-3">
                What do we Guarantee?
              </h2>
              <img
                src={require("../Images/homeimg3.png")}
                alt="img"
                className="img-fluid w-100 px-3"
              />
            </div>

            <div className="  col-md-7 col-sm-12 col-12">
              <div className="  container col-md-12 col-sm-12">
                <div className="ps-lg-4">
                  <h3 className="d-none d-md-block">What do we Guarantee?</h3>
                  <p className="text">
                    We promise more than just design—we assure a seamless
                    experience, enduring quality, and a decade-long commitment
                    to your satisfaction.
                  </p>
                </div>
                <div className=" row col-md-12 pt-md-5">
                  <div className="  row col-md-12  ">
                    <div className="  container d-flex flex-wrap row col-md-6 ps-4 ms-3 ps-md-0 ms-md-0">
                      <div className="homepage_icontxt col-md-12 mb-2">
                        <img
                          src={require("../Images/customize.png")}
                          className="img-fluid"
                          alt=""
                        />
                        <p>Customised Designs</p>
                      </div>
                      <div className="homepage_icontxt col-md-12 pt-md-4 mb-2">
                        <img
                          src={require("../Images/deliveryday.png")}
                          className="img-fluid"
                          alt=""
                        />
                        <p>In 60 Days Delivery</p>
                      </div>
                    </div>
                    <div className=" row col-md-6 ps-4 ms-3 ps-md-0 ms-md-0 ">
                      <div className="homepage_icontxt col-md-12 mb-2">
                        <img
                          src={require("../Images/premium.png")}
                          className="img-fluid"
                          alt=""
                        />
                        <p>Premium Finishing</p>
                      </div>
                      <div className="homepage_icontxt col-md-11 pt-md-4 mb-2 ">
                        <img
                          src={require("../Images/solutions.png")}
                          className="img-fluid"
                          alt=""
                        />
                        <p>360° Solutions</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" material-info justify-content-center">
        <div className="container mb-md-5 mb-3 px-md-5 material-head">
          <h3 className="container">End-To-End Building Solutions</h3>
        </div>
        <div className="container row col-md-12  materialbox">
          <div className="material material-spcl col-md-2 shadow-sm  pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/modular.png")} alt="civil" />
            <p className="mt-2 mt-md-0 spcl-title">Modular Kitchen</p>
          </div>
          <div className="material col-md-2 shadow-sm pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/wallpaint.png")} alt="civil" />
            <p>Wall Paint</p>
          </div>
          <div className="material material-spcl col-md-2 shadow-sm pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/materialbedroom.png")} alt="civil" />
            <p>Bedroom</p>
          </div>
          <div className="material col-md-2 shadow-sm  pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/poojaunits.png")} alt="civil" />
            <p>Pooja Units</p>
          </div>
        </div>
        <div className="container row col-md-12 col-sm-12 materialbox">
          <div className="material material-spcl  col-md-2 shadow-sm col-sm-6 pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/civilservice.png")} alt="civil" />
            <p>Civil Services</p>
          </div>
          <div className="material col-md-2 shadow-sm col-sm-6 pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/lights.png")} alt="civil" />
            <p>Lights</p>
          </div>
          <div className="material material-spcl  col-md-2 shadow-sm col-sm-6 pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/studytable.png")} alt="civil" />
            <p>Study Table</p>
          </div>
          <div className="material col-md-2 shadow-sm col-sm-6 pt-3 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/falsecelling.png")} alt="civil" />
            <p>False Ceiling</p>
          </div>
        </div>
        <div className="container row col-md-12 col-sm-12 materialbox">
          <div className="material material-spcl  col-md-2 shadow-sm col-sm-6 pt-3 mb-md-5 mb-4  bg-white rounded rounded-3">
            <img src={require("../Images/wardrobe.png")} alt="civil" />
            <p>Wardrobe</p>
          </div>
          <div className="material col-md-2 shadow-sm col-sm-6 pt-3 mb-md-5 mb-4 px-2 bg-white rounded rounded-3">
            <img src={require("../Images/furniture.png")} alt="civil" />
            <p className="mt-2 mt-md-0 spcl-title">Movable Furniture</p>
          </div>
          <div className="material material-spcl  col-md-2 shadow-sm col-sm-6 pt-3 mb-5  bg-white rounded rounded-3">
            <img src={require("../Images/materialbathroom.png")} alt="civil" />
            <p>Bathroom</p>
          </div>
          <div className="material col-md-2 shadow-sm col-sm-6 pt-3 mb-5  bg-white rounded rounded-3">
            <img src={require("../Images/tvunits.png")} alt="civil" />
            <p>TV Units</p>
          </div>
        </div>

        <a
          className="materialboxbtn text-decoration-none px-4 py-2 mb-3"
          href="#footer"
          type="button"
        >
          Book a Design Session
        </a>
      </section>

      <section className="pb-5 ">
        <div className=" container">
          <div className="container view-text col-md-12 d-flex justify-content-between align-items-center px-4 pt-5 ">
            <h1>Interior Services </h1>
            <a
              href="/interiorresidential"
              className="text-decoration-none primary-txt view-text view-text me-1 fs-5"
            >
              View all &nbsp;
              <EastIcon />
            </a>
          </div>
          <HomeInteriorProjects />
        </div>

        <div className=" container">
          <div className=" container  col-md-12 d-flex view-text justify-content-between align-items-center px-4 pt-5">
            <h1>Architectural Services </h1>
            <a
              href="/archiresidential"
              className="text-decoration-none primary-txt  me-1 fs-5"
            >
              View all &nbsp;
              <EastIcon />
            </a>
          </div>
          <HomeArchitectProjects />
        </div>

        <div className=" container">
          <div className=" container col-md-12 d-flex view-text justify-content-between align-items-center px-2 pt-md-4 pt-5 py-0 mx-3">
            <h1>Turnkey Services </h1>
            <a
              href="/turnkey"
              className="text-decoration-none primary-txt view-text me-4 fs-5"
            >
              View all &nbsp;
              <EastIcon />
            </a>
          </div>
          <HomeTrunkeyProjects />
        </div>
      </section>

      <div id="footer">
        <HomeFooter />
      </div>
    </div>
  );
};

export default HomePage;
