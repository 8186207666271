import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import ArcCommercial from "./Pages/ArcCommercial";
import ArcResidential from "./Pages/ArcResidential";
import InteriorCommercial from "./Pages/InteriorCommercial";
import InteriorResidential from "./Pages/InteriorResidential";
import Trunkey from "./Pages/Trunkey";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import LogoFooter from "./Component/LogoFooter";
import Footer from "./Component/Footer";
// import TopNavbar from "./Component/Navbar";
import Header from "./Component/Header";
// import Navbar from "./Component/Navbar";
import HomeFooter from "./Component/HomeFooter";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      {/* <Navbar />
      <MainRoutes />
      <Footer /> */}
      {/* <Navbar /> */}
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contatform" element={<HomeFooter />} />

          <Route path="/archicommercial" element={<ArcCommercial />} />
          <Route path="/archiresidential" element={<ArcResidential />} />
          <Route path="/interiorcommercial" element={<InteriorCommercial />} />
          <Route path="/interiorresidential" exact element={<InteriorResidential />} />
          <Route path="/turnkey" element={<Trunkey />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
      {/* <OtherFooter /> */}
      <LogoFooter />
      <Footer />
    </div>
  );
}
export default App;
