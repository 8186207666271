import React from "react";
import { Modal } from "react-bootstrap";
import "./Popup.css";
import ImageSlider from "./ImageSlider";


export default function Popup({ setModal, modal, currentData }) {
  return (
    <>
      {currentData && (
        <Modal show={modal} size="lg" >
          <Modal.Body className="pop-bg d-flex flex-wrap w-100 ">
            <div className="d-flex justify-content-between w-100">
              <h2>{currentData.pophead}</h2>
              <button
                type="button"
                className="btn-close slider-btn"
                aria-label="Close"
                onClick={() => {
                  setModal(false);
                }}
              />
            </div>
            <div className="col-md-12 col-12 align-self-center ">
              <ImageSlider popupImg={currentData.popImg} />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
