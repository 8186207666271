import React from "react";
import "./AboutUs.css";
import about from "../Images/about.png";

const AboutUs = () => {
  const width = window.innerWidth
  return (
    <div>
      <div className="about_main">
        <div className="">
          <div className=" col-md-7 col-sm-12 img-container">
            <img
              src={require("../Images/about_img.png")}
              className="img-fluid w-100"
              alt="img"
            />
          </div>
          <div className="aboutus-overtext  col-md-6">
            <h1 className="my-3 mt-md-0 mb-md-2">About Us</h1>
            <p>
              M's Design Studio: Crafting refined and meaningful Architecture &
              Interior Designs for nearly 10 years. From custom furniture and
              lighting to complete interiors, we specialize in residential,
              institutional, hospitality, health service, and industrial
              projects of all sizes. Experience the interplay of architecture,
              space, form, and materials as we bring your vision to life.
            </p>
          </div>
        </div>

        <div className=" text-center p-3 ">
          <h1 className="pt-4">Our Team</h1>
          <p className="mb-0">
            Behind our team's success lies a shared passion for exceptional
            design <br />
            and a relentless commitment to exceeding client expectations.
          </p>
        </div>

        <div className=" container d-flex flex-wrap col-md-12 text-center p-md-5 py-4 px-3">
          <div className=" col-md-3 pe-3 pb-3 abtFold">
            <img
              src={require("../Images/director.png")}
              alt=""
              className="img-fluid w-100"
            />
            <div className="director pt-3 ">
              <h3 className="text-center">Gaurav N. Mehta</h3>
              <p className="text-center mobSpec">Director</p>
            </div>
          </div>
          <div className="col-md-3 pe-3 pb-3 abtFold">
            <img
              src={require("../Images/directorarchitect.png")}
              alt=""
              // style={{ height: "52vh" }}
              className="img-fluid w-100"
            />
            <div className="director pt-3">
              <h4 className="text-center special-h4">Ar. Amruta K Doshi</h4>
              <p className="text-center architect mb-0">Director & Principal Architect</p>
            </div>
          </div>
          <div className=" col-md-3 pe-3 pb-3 abtFold">
            <img
              src={require("../Images/architect.png")}
              alt=""
              className="img-fluid w-100 "
            />
            <div className="director pt-3">
              <h3 className="text-center">Ar. Nirmit Shah</h3>
              <p className="text-center">Principal Architect</p>
            </div>
          </div>
          <div className="col-md-3 pe-3 pb-3 abtFold">
            <img
              src={require("../Images/designer.png")}
              alt=""
              className="img-fluid w-100"
            />
            <div className="director pt-3">
              <h3 className="text-center">Meera K Doshi</h3>
              <p className="text-center mobSpec">Designer</p>
            </div>
          </div>
        </div>

        <div className=" p-3">
          <div className="container text-center">
            <h1>What We Promise </h1>
            <div className=" col-sm-12 row ">
              <div className="col-sm-12 col-md-4 col-xs-12">
                <div className="col-sm-12 thumbnail">
                  <img src={require("../Images/Icon1.png")} alt="" className="w-25 px-md-3 pe-3 promise-icon" />
                  <div className="promise-text p-3">
                    <h4>Trusted Partnership</h4>
                    <p>
                      Building trusted partnerships for craftsmanship and to
                      top-quality materials.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-xs-12">
                <div className="col-sm-12 thumbnail">
                  <img src={require("../Images/Icon2.png")} alt="" className="w-25 px-md-3 pe-3 promise-icon" />

                  <div className="promise-text p-3">
                    <h4>On Time Project Delivery</h4>
                    <p>
                      Punctuality at its finest - delivering projects on time,
                      every time.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 col-xs-12">
                <div className="col-sm-12 thumbnail ">
                  <img src={require("../Images/Icon3.png")} alt="" className="w-25 px-md-3 pe-3 promise-icon" />

                  <div className="promise-text p-3">
                    <h4>Client Referral Rate</h4>
                    <p>
                      Our clients' trust speaks volumes - a 100% client referral
                      rate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" container justify-content-center d-flex flex-wrap col-sm-12 col-md-12 p-md-5 py-md-5 pt-3 pb-5 px-3">
          <div className="   col-md-5 ">
            <img src={about} className="img-fluid" alt="img" />
          </div>

          <div className="   narrative col-md-7 ">
            <h2 className="mt-4 mt-md-0">Our Narrative</h2>
            <p className="pt-3">
              M's Design Studio has been creating refined, meaningful
              Architecture & Interior Designs for our discerning clientele for
              nearly 10 years. We specialize in full- services including custom
              furniture, lighting to Complete Interior and all type of
              construction work. Our project includes both new home construction
              and renovations of residential, institutional, hospitality, health
              service & industrial buildings of all sizes, small to large.
              Responsive to our clients' vision, we are committed to design that
              expresses the interrelationships between architecture and place,
              space and form, color and materials, economy and integrity.
            </p>
          </div>
        </div>

        {/* <section className=" container d-md-flex flex-wrap displayaward mt-md-5 pb-md-5">
          <div className="displayaward col-md-3 pt-md-4 col-12 pb-3 pb-md-0">
            <h1>
              Awards & <br />
              Recognitions
            </h1>
          </div>
          <div className="container d-flex col-md-9 ">
            <div className="    col-md-6 ">
              <div className=" row bg-white col-md-12 pt-3 pb-md-3 me-2">
                <div className="col-md-3">
                  <img
                    src={require("../Images/skillwaterproofer.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-9 awardcard">
                  <h4 className="fs-4 mb-0 mt-md-3 ">Skill Waterproofer</h4>
                  <p className="fs-5" style={{ color: "gray" }}>
                    A Pidilite Brand
                  </p>
                </div>
              </div>
              <div className=" row bg-white col-md-12 pt-md-3 pb-md-3 mt-3 me-2">
                <div className="col-md-3">
                  <img src={require("../Images/turnkeypartner.png")} alt="" />
                </div>
                <div className="col-md-9 awardcard1">
                  <h4 className="fs-4 mb-0 mt-md-3">
                    Turnkey Contracting Partner
                  </h4>
                  <p className="fs-5" style={{ color: "gray" }}>
                    Nikhil Construction Group
                  </p>
                </div>
              </div>
            </div>
            <div className="   col-md-6 ">
              <div className=" row bg-white col-md-12 pt-md-3 pb-md-3 mx-md-3 ms-1 ">
                <div className="col-md-3">
                  <img src={require("../Images/goldenbrush.png")} alt="" />
                </div>
                <div className="col-md-9 awardcard px-3">
                  <h4 className="fs-4 mb-0 mt-md-3 mt-3">
                    Golden Brush Applicator
                  </h4>
                  <p className="fs-5" style={{ color: "gray" }}>
                    Asian Paints
                  </p>
                </div>
              </div>
              <div className=" row bg-white col-md-12 pt-3 pb-md-3 mt-3 mx-md-3 ms-1 ">
                <div className="col-md-3">
                  <img
                    src={require("../Images/professionalpainting.png")}
                    alt=""
                  />
                </div>
                <div className="col-md-9 awardcard px-2">
                  <h4 className="fs-4 mb-0 mt-4 ">
                    Professional Painting Contractor
                  </h4>
                  <p className="fs-5" style={{ color: "gray" }}>
                    Mitcon Institue
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className="award-recog">
          <div className="container d-md-flex flex-wrap align-items-center py-3">
              <div className="col-md-4 col-12">
                <h1>Awards & <br /> Recognitions</h1>
              </div>
              <div className="col-md-8 col-12">
                <div className={`${width < 480 ? '' : 'container'} d-flex flex-wrap align-items-center`}>
                  <div className="col-md-6 col-12 my-2 px-2">
                    <div className="awardcard d-flex flex-wrap align-items-center">
                      <div className="">
                        <img src={require("../Images/skillwaterproofer.png")} alt="Waterproof" className="img-fluid w-100" />
                      </div>
                      <div className="ps-3">
                        <h5 className="">Skill Waterproofer</h5>
                        <p className="light-color">A Pidilite Brand</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 my-2 px-2">
                    <div className="awardcard d-flex flex-wrap align-items-center">
                      <div className="">
                        <img src={require("../Images/goldenbrush.png")} alt="Waterproof" className="img-fluid w-100" />
                      </div>
                      <div className="ps-3">
                        <h5 className="">Golden Brush <br className="d-flex d-md-none" /> Applicator</h5>
                        <p className="light-color">Asian Paints</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 my-2 px-2">
                    <div className="awardcard d-flex flex-wrap align-items-center">
                      <div className="">
                        <img src={require("../Images/turnkeypartner.png")} alt="Waterproof" className="img-fluid w-100" />
                      </div>
                      <div className="ps-3">
                        <h5 className="">Professional Painting <br className="d-block" /> Contractor</h5>
                        <p className="light-color">Mitcon Institute</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 my-2 px-2">
                    <div className="awardcard d-flex flex-wrap align-items-center">
                      <div className="">
                        <img src={require("../Images/professionalpainting.png")} alt="Waterproof" className="img-fluid w-100" />
                      </div>
                      <div className="ps-3">
                        <h5 className="turnk-head">Turnkey Contracting <br className="d-block" /> Partner</h5>
                        <p className="light-color">Nikhil Construction Group</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>

        {/* <div id="footer" className="mt-5">
          <OtherFooter />
        </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
