import React from "react";
import "./ArcResidential.css";
import Design from "../Images/Design.png";
import process1 from "../Images/process1.png";
import process2 from "../Images/process2.png";
import process3 from "../Images/Develop.png";
import process4 from "../Images/process4.png";
import Gola from "../Images/circle.png";
import OtherFooter from "../Component/OtherFooter";
import ArchResProjects from "../Component/ArchResProjects";

const ArcResidential = () => {
  return (
    <>
      <div id="archihome">
        <div className="residential_imgtext">
          <p>Architecture</p>
          <div className="headings col-md-4 col-10 mx-auto">
            <h3 className="pt-3 pt-md-0">Residential Services</h3>
          </div>
        </div>
      </div>

      <div className="residential_para">
        <p>
          Welcome to our Architectural Design Services, where we transform your
          ideas into stunning structures. Our expertise encompasses both
          residential and commercial projects, offering tailored solutions for
          your needs.
        </p>
        <p>
          At M’s Design Studio, our team of experts is committed to crafting
          spaces that effortlessly fuse style and functionality, turning houses
          into personalized havens. We infuse vitality into every corner,
          ensuring each area mirrors your distinctive lifestyle. Explore our
          residential architectural services and embark on a transformative
          journey towards a uniquely designed home.
        </p>
      </div>

      <div className="residential_offer">
        <p>
          Residential Services <br /> We Offer
        </p>

        <ArchResProjects />

        <p>
          Our Architectural <br /> Design Process
        </p>
        <img src={Design} alt="" />
        <div className="residential_design position-relative">
          <div className="design_process">
            <img src={process1} alt="" className="mb-5" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-2 gola1" />
              <h4 className="desh4 mt-3 mt-lg-5 px-2 mx-2">Consultation</h4>
              <p className="ps-3 desProcess">
                In this phase, we engage in open discussions to grasp your needs
                and aspirations, laying the foundation for a personalized
                project.
              </p>
            </div>
          </div>

          <div className="design_process">
            <img src={process2} alt="" className="mb-5" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-2 gola2" />
              <h4 className="desh4 mt-3 mt-lg-5 px-2 arch-spl">Design</h4>
              <p className="ps-3 desProcess">
                This step involves translating your ideas into detailed plans,
                sketching out the blueprint of your vision.
              </p>
            </div>
          </div>

          <div className="design_process">
            <img src={process3} alt="" className="mb-5" />
            <div className="design_display">
              <img src={Gola} alt="" className="gola d-block m-2 gola3" />
              <h4 className="desh4 mt-3 mt-lg-5 px-2 mx-2">Develop</h4>
              <p className="ps-3 desProcess">
                We refine and expand upon the chosen design, focusing on
                enhancing functionality and aesthetics while considering
                practicality.
              </p>
            </div>
          </div>

          <div className="design_process">
            <img src={process4} alt="" className="mb-5" />
            <div className="design_display arch-spl">
              <img src={Gola} alt="" className="gola d-block m-2 gola4" />
              <h4 className="desh4 mt-3 mt-lg-5 px-2 arch-spl">Implement</h4>
              <p className="ps-3 desProcess">
                Here, we turn plans into reality, bringing your project to life
                with precise execution and attention to detail.
              </p>
            </div>
          </div>

          <div className="container position-absolute arch-des-proc">
            <hr className="" />
          </div>
        </div>
      </div>

      <div id="footer">
        <OtherFooter />
      </div>
    </>
  );
};

export default ArcResidential;
