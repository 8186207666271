import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "./QuotePopup.css";

export default function QuotePopup({ modal, setModal }) {
  const [formData, setformData] = useState({});
  // const location = useLocation();
  // const path = location.pathname;
  const handleChange = (event) => {
    const { name, value } = event.target;
    let nameValue = name;
    let valueValue = value;
    setformData({ ...formData, [nameValue]: valueValue });

    if (nameValue === "name") {
      const errorElement = document.getElementById("nameError");
      errorElement.textContent =
        valueValue.trim() === "" ? "Name is required" : "";
    } else if (nameValue === "contactNo") {
      const contactNoPattern = /^\d{10}$/;
      const errorElement = document.getElementById("contactNoError");
      errorElement.textContent = !contactNoPattern.test(valueValue)
        ? "Invalid contact number (10 digits required)"
        : "";
    } else if (nameValue === "service") {
      const errorElement = document.getElementById("dropdownError");
      errorElement.textContent = "";
    } else if (nameValue === "AppartmentType") {
      const errorElement = document.getElementById("apartmentdropdownError");
      errorElement.textContent = "";
    } else if (nameValue === "TentativeBudget") {
      const errorElement = document.getElementById("TentativeBudgetError");
      errorElement.textContent = "";
    } else if (nameValue === "city") {
      const errorElement = document.getElementById("cityError");
      errorElement.textContent =
        valueValue.trim() === "" ? "City is required" : "";
    }
  };

  const submitForm = (e) => {
    const config = {
      SecureToken: process.env.REACT_APP_SECURITY_TOKEN,
      To: process.env.REACT_APP_EMAIL_ID,
      From: process.env.REACT_APP_FROM_EMAIL_ID,
      Subject: "New enquiry received to Book a Design Session",
      Body: `
              Full Name : ${formData.name} <br />
              Phone Number : ${formData.contactNo} <br />
              Service : ${formData.service} <br />
              Appartment Type : ${formData.AppartmentType} <br />
              Tentative Budget : ${formData.TentativeBudget} <br />
              City : ${formData.city}
            `,
    };

    if (
      formData.service &&
      formData.AppartmentType &&
      formData.TentativeBudget
    ) {
      if (window.Email) {
        window.Email.send(config)
          .then((res) => {
            document.getElementById("homeFoot").reset();
            // console.log(res);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            toast.success(
              "Thank you for reaching out to us. We have received your message. We will get back to you soon.",
              {
                position: "top-right", // Toast position
                autoClose: 2500, // Duration in milliseconds
                hideProgressBar: false, // Whether to display a progress bar
                closeOnClick: true, // Close toast on click
                pauseOnHover: true, // Pause the autoClose timer on hover
                draggable: true, // Enable dragging to dismiss the toast
                progress: undefined, // Custom progress bar component
              }
            );
          })
          .catch((err) => {
            // console.log(err);
            toast.error("Opps! Something went wrong.", {
              position: "top-right", // Toast position
              autoClose: 2500, // Duration in milliseconds
              hideProgressBar: false, // Whether to display a progress bar
              closeOnClick: true, // Close toast on click
              pauseOnHover: true, // Pause the autoClose timer on hover
              draggable: true, // Enable dragging to dismiss the toast
              progress: undefined, // Custom progress bar component
            });
          });
      }
    } else {
      if (!formData.service) {
        const errorElement = document.getElementById("dropdownError");
        errorElement.textContent = "Please select a service";
      }
      if (!formData.AppartmentType) {
        const errorElement = document.getElementById("apartmentdropdownError");
        errorElement.textContent = "Please select a type";
      }
      if (!formData.TentativeBudget) {
        const errorElement = document.getElementById("TentativeBudgetError");
        errorElement.textContent = "Please select a budget";
      }
    }
  };

  return (
    <Modal show={modal} size="lg" className="mt-5">
      <Modal.Body className="pop-bg d-flex flex-wrap w-100 ">
        <div className="d-flex justify-content-between w-100">
          <h4>Book a design session</h4>
          <button
            type="button"
            className="btn-close slider-btn"
            aria-label="Close"
            onClick={() => {
              setModal(false);
            }}
          />
        </div>

        <div className="mt-2">
          <form
            className=" quote-popup col-md-4 mt-md-0 p-3"
            onSubmit={(e) => {
              submitForm(e);
              e.preventDefault();
            }}
            id="homeFoot"
          >
            {/* <label htmlFor="name" className="contact-form-label">
            {" "}
            Name{" "}
          </label> */}
            <input
              required
              className="contact-form-input  "
              type="text"
              onChange={handleChange}
              name="name"
              placeholder="Name"
            />
            <small className="error" id="nameError"></small>

            {/* <label htmlFor="contact-no" className="contact-form-label mt-4">
            {" "}
            Contact Number{" "}
          </label> */}
            <input
              required
              className="contact-form-input mt-4"
              type="tel"
              minLength={10}
              maxLength={10}
              onChange={handleChange}
              name="contactNo"
              placeholder="Contact Number"
            />
            <small className="error" id="contactNoError"></small>

            <select
              required
              className={`${
                formData.service ? "" : "selectPlaceholder"
              } contact-form-input mt-4 pt-2`}
              type="text"
              onChange={handleChange}
              name="service"
            >
              <option selected disabled className="droplabel">
                Select a Service
              </option>
              <option>Architecture</option>
              <option>Interior</option>
              <option>Turnkey</option>
            </select>
            <small className="error" id="dropdownError"></small>

            <select
              required
              className={`${
                formData.AppartmentType ? "" : "selectPlaceholder"
              } contact-form-input mt-4 pt-2`}
              type="text"
              onChange={handleChange}
              name="AppartmentType"
            >
              <option selected disabled>
                Apartment type
              </option>
              <option>Flat - 2BHK</option>
              <option>Flat - 3/4 BHK</option>
              <option>Bunglow/Villa</option>
            </select>
            <small className="error" id="apartmentdropdownError"></small>

            <select
              required
              className={`${
                formData.TentativeBudget ? "" : "selectPlaceholder"
              } contact-form-input mt-4 pt-2`}
              type="text"
              onChange={handleChange}
              name="TentativeBudget"
            >
              <option selected disabled>
                Tentative Budget
              </option>
              <option>Below 5 lakhs</option>
              <option>5 lakhs to 10 Lakhs</option>
              <option>10 lakhs to 50 lakhs</option>
              <option>50 Lakhs and Above</option>
            </select>
            <small className="error" id="TentativeBudgetError"></small>

            {/* <label
            htmlFor="TentativeBudget"
            className="contact-form-label mt-4 pb-1"
          >
            {" "}
            Tentative Budget{" "}
          </label> */}
            {/* <input
            required
            className="contact-form-input "
            type="number"
            onChange={handleChange}
            name="TentativeBudget"
          />
          <small className="error" id="TentativeBudgetError"></small> */}

            {/* <label htmlFor="city" className="contact-form-label mt-4 pb-1">
            {" "}
            Enter Your City{" "}
          </label> */}
            <input
              required
              className="contact-form-input mt-4 "
              type="text"
              onChange={handleChange}
              name="city"
              placeholder="City"
            />
            <small className="error" id="cityError"></small>

            <div className="center mt-2 homefooter">
              <button
                type="submit"
                className="contact-fornm-btn mt-3 w-100"
                value="Book a Design Session"
              >
                Book a Design Session
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
